import {axiosClient} from '../config/axios';
import swal from 'sweetalert';
import cookie from 'js-cookie'

export const axiosFormdataPost = async(url, formData, objectName) => {
  try{
    const token = cookie.get("token")
    const response = await axiosClient.post(url, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
    swal("Operación exitosa", `El ${objectName} se ha cargado correctamente`, "success",{button:  "Aceptar"});
    return response;
  }catch(error){
    swal("Ha ocurrido un error", `El ${objectName} no ha sido cargado`, "error",{button:  "Aceptar"});
    console.error("axiosFormdataPost request: ", formData)
    console.error("Error in axiosFormdataPost", error, error.response)
    return null;
  }
}

export const axiosFormdataPut = async(url, formData, objectName) => {
  try{
    const token = cookie.get("token")
    const response = await axiosClient.put(url, formData,  {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
    swal("Operación exitosa", `El ${objectName} se ha actualizado correctamente`, "success",{button:  "Aceptar"});
    return response;
  }catch(error){
    swal("Ha ocurrido un error", `El ${objectName} no ha sido actualizado`, "error",{button:  "Aceptar"});
    console.error("axiosFormdataPut request: ", formData)
    console.error("Error in axiosFormdataPut", error, error.response)
    return null;
  }
}

export const axiosClientGet = async(url) => {
  try{
    const token = cookie.get("token")
    const response = await axiosClient.get(url,{
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response;
  }catch(error){
    console.error("axiosClientGet request: ", url)
    console.error("Error in axiosClientGet", error, error.response)
    console.error(error.response.data);
    return null;
  }
}

export const axiosClientPost = async(url, data, objectName, ommitSwal) => {
  try{
    const token = cookie.get("token")
    const response = await axiosClient.post(url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
    if(!ommitSwal){
      swal("Operación exitosa", `El ${objectName} se ha cargado correctamente`, "success",{button:  "Aceptar"});
    }
    return response;
  }catch(error){
    if(!ommitSwal){
      if(error.response.data) swal("Ha ocurrido un error",error.response.data.message, "error",{button:  "Aceptar"});
      else swal("Ha ocurrido un error", `El ${objectName} no ha sido cargado`, "error",{button:  "Aceptar"});
    }
    console.error("axiosClientPost request: ", data)
    console.error("Error in axiosClientPost", error, error.response)
    return null;
  }
}

export const axiosClientPut = async(url, data, objectName) => {
  try{
    const token = cookie.get("token")
    const response = await axiosClient.put(url, data,  {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
    swal("Operación exitosa", `El ${objectName} se ha actualizado correctamente`, "success",{button:  "Aceptar"});
    return response;
  }catch(error){
    swal("Ha ocurrido un error", `El ${objectName} no ha sido actualizado`, "error",{button:  "Aceptar"});
    console.error("axiosClientPut request: ", data)
    console.error("Error in axiosClientPut", error, error.response)
    return null;
  }
}

export const axiosClientDelete = async(url, objectName) => {
  try{
    const token = cookie.get("token")
    const response = await axiosClient.delete(url,  {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
    swal("Operación exitosa", `El ${objectName} se ha eliminado correctamente`, "success",{button:  "Aceptar"});
    return response;
  }catch(error){
    swal("Ha ocurrido un error", `El ${objectName} no ha sido eliminado`, "error",{button:  "Aceptar"});
    console.error("axiosClientDelete request: ", url)
    console.error("Error in axiosClientDelete", error, error.response)
    return null;
  }
}