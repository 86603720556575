import React, {useEffect, useState, useContext} from 'react'
import { useHistory } from 'react-router-dom'
import * as RestService from '../../services/RestService'
import { Image, Col } from 'react-bootstrap';
import Product from './Product'
import CustomTable from '../utilities/CustomTable'
import {Context} from '../../context/context'
import { Tag } from 'antd';

const ProductList = () => {

  const history = useHistory()
  const [productList, setProductList] = useState([])

  const [loadingTable, setLoadingTable] = useState(true)
  const [context, ] = useContext(Context)

  var map ={}

  useEffect(() => {

    const apiRequest = async () =>  {
      try{
        const productsResponse = await RestService.axiosClientGet('/service/product')
        let productsList = productsResponse.data
        productsList.map(product => {
          product.key = product.id
          product.categoryName = product.productCategory.name
          return Product
        })
        setProductList(productsList)

        const categoriesResponse = await RestService.axiosClientGet('/service/product_category')
        let categoriesList = categoriesResponse.data
        map ={}
        categoriesList.map(category => {
          map[category.id] = category.name
        })

        setLoadingTable(false)
      }catch(e){
        console.error("Error in ProductList", e)
        history.push("/logout")
      }
    }

    apiRequest()

 }, [ history, context.reloadProductList])

 const columns = [
  {
    title: 'Nombre',
    dataIndex: 'name',
    key: 'name',
    width: '15%',
    isSearchable: true,
  },
  {
    title: 'Imagen',
    key: 'imagePath',
    width: '22%',
    render: (text, record) => (
      <Col md={10}>
        <Image src={record.imagePath} thumbnail />
      </Col>
    ),
  },
  {
    title: 'Precio',
    dataIndex: 'price',
    key: 'price',
    width: '10%',
  },
  {
    title: 'Tiempo Estimado',
    dataIndex: 'estimatedTime',
    key: 'estimatedTime',
    width: '10%',
  },
  {
    title: 'Categoría',
    dataIndex: 'categoryName',
    key: 'categoryName',
    width: '15%',
    isSearchable: true
  },
  {
    title: 'Contenido',
    dataIndex: 'contentList',
    key: 'contentList',
    width: '15%',
    render: (text, record) => {
      return (
        record.contentList.map(content => (
          <Tag style={{margin: '3%' }} key={content.id} color="#2db7f5">
          {`${content.name} (${content.quantity})`}
        </Tag>
        )
      )
    )
  }},
  {
    title: 'Adiciones',
    dataIndex: 'additionsCategories',
    key: 'additionsCategories',
    width: '15%',
    render: (text, record) => {
      return (
        record.additionsCategories.map(id => (
          <Tag style={{margin: '3%' }} key={id} color="#2db7f5">
          {map[id]}
        </Tag>
        )
      )
    )
  }},
  {
    title: 'Estado',
    key: 'isEnabled',
    width: '10%',
    dataIndex: 'isEnabled',
    filters: [
      {
        text: 'Habilitado',
        value: true,
      },
      {
        text: 'Deshabilitado',
        value: false,
      },
    ],
    onFilter: (value, record) => record.isEnabled === value,
    render: (text, record) => (
      <Tag color={record.isEnabled ? "green" : "volcano"}>
        {record.isEnabled ? "Habilitado" : "Deshabilitado"}
      </Tag>
      )
  },
  {
    title: 'Actualizar',
    width: '10%',
    key: 'action',
    actionType: 'update',
    align: 'center'
  },
];

return(
  <CustomTable 
    loadingTable={loadingTable} 
    columns={columns} 
    dataSource={productList} 
    component={Product}
    title={ <h3 className="text-primary">Productos</h3> }
    buttonText={"Crear Producto"}
  />
)
}

export default ProductList