import React, {useEffect, useState, useContext} from 'react'
import { useHistory } from 'react-router-dom'
import * as RestService from '../../services/RestService'
import { Col, Modal, Container, Button, Row, Image} from 'react-bootstrap';
import {  CloseCircleOutlined } from '@ant-design/icons';

import Office from './Office'
import CustomTable from '../utilities/CustomTable'
import {Context} from '../../context/context'
import { Tag  } from 'antd';
import Settings from '../settings-component/Settings'

const OfficeList = () => {

  const history = useHistory()
  const [officeList, setOfficeList] = useState([])
  const [loadingTable, setLoadingTable] = useState(true)
  const [context, ] = useContext(Context)
  const [showModal, setshowModal] = useState(false);


  useEffect(() => {

    const apiRequest = async () =>  {
      try{
        const officeResponse = await RestService.axiosClientGet('/service/office')
        let officeList = officeResponse.data
        officeList.map(office => office.key = office.id)
        setOfficeList(officeList)
        setLoadingTable(false)
      }catch(e){
        console.error("error in OfficeList", e)
        history.push("/logout")
      }
    }

    apiRequest()

 }, [ history, context.reloadOfficeList])

 const columns = [
  {
    title: 'Nombres',
    dataIndex: 'name',
    key: 'name',
    width: '13%',
    isSearchable: true
  },
  {
    title: 'Dirección',
    dataIndex: 'address',
    key: 'address',
    width: '13%',
    isSearchable: true
  },
  {
    title: 'Imagen',
    key: 'imagePath',
    width: '27%',
    render: (text, record) => (
      <Col md={10}>
        <Image src={record.imagePath} thumbnail />
      </Col>
    ),
  },
  {
    title: 'Teléfono',
    dataIndex: 'phone',
    key: 'phone',
    width: '9%',
    isSearchable: true
  },
  {
    title: 'Latitud',
    dataIndex: 'latitude',
    key: 'latitude',
    width: '9%',
    isSearchable: true
  },
  {
    title: 'Longitud',
    dataIndex: 'longitude',
    key: 'longitude',
    width: '9%',
    isSearchable: true
  },
  {
    title: 'Municipio',
    dataIndex: 'municipality',
    key: 'municipality',
    width: '10%',
    isSearchable: true
  },
  {
    title: 'Estado',
    key: 'isEnabled',
    width: '10%',
    dataIndex: 'isEnabled',
    filters: [
      {
        text: 'Habilitado',
        value: true,
      },
      {
        text: 'Deshabilitado',
        value: false,
      },
    ],
    onFilter: (value, record) => record.isEnabled === value,
    render: (text, record) => (
      <Tag color={record.isEnabled ? "green" : "volcano"}>
        {record.isEnabled ? "Habilitado" : "Deshabilitado"}
      </Tag>
      )
  },
  {
    title: 'Actualizar',
    key: 'action',
    actionType: 'update',
    width: '10%',
    align: 'center'
  },
  ];

  const getTitle = () => {
    return (
      <Row>
        <Col sm={10}>
          <h3 className="text-primary">Asaderos</h3>
        </Col>

        <Modal size="lg" show={showModal} onHide={() => setshowModal(false)}>
          <Container style={{padding: "20px"}}>
          <CloseCircleOutlined  className="float-right" onClick={() => setshowModal(false)} style={{ fontSize: '25px', color: '#08c' }}/>
            <Settings closeModal={() => setshowModal(false)}/>
          </Container>
        </Modal>

      </Row>
    )
  }


  return(
    <CustomTable 
      loadingTable={loadingTable} 
      columns={columns} 
      dataSource={officeList} 
      component={Office}
      title={getTitle()}
      buttonText={"Crear Asadero"}
    />
  )
}

export default OfficeList