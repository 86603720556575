import React, {useState, useEffect, useContext} from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { Spin, Checkbox, Tag } from 'antd';
import AlertForm from '../utilities/AlertForm';
import * as RestService from '../../services/RestService';
import {Context} from '../../context/context';
import Select from 'react-select';


const Users = (props) => {

  const history = useHistory();
  const { handleSubmit, register, errors, setValue } = useForm();
  const [isEnabled, setIsEnabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isUpdatePassword, setIsUpdatePassword] = useState(false);
  const [context, setContext] = useContext(Context);
  const [roleObject, setRoleObject] = useState(null);

  const [officesList, setOfficesList] = useState([]);
  const [isLoadingOffices, setIsLoadingOffices] = useState(false);
  const [officeObject, setOfficeObject] = useState(null);

  const [selectOptions, setSelectOptions] = useState([
    { value: 'ROLE_OPERATOR', label: 'OPERADOR' },
    { value: 'ROLE_SUPPORT', label: 'SOPORTE' },
    { value: 'ROLE_DELIVERY_MAN', label: 'DOMICILIARIO' }
  ]);

  useEffect(() => {

    if(props.dataFromParent.isOnlyForDeliveryUsers){
      setSelectOptions([{ value: 'ROLE_DELIVERY_MAN', label: 'DOMICILIARIO' }])
    }

    getOfficeList();
    if(props.data){
      setValue("name", props.data.name)
      setValue("lastName", props.data.lastName)
      setValue("userName", props.data.userName)
      setValue("cellPhoneNumber", props.data.cellPhoneNumber)
      setValue("password", "**********")
      setIsEnabled(props.data.isEnabled)
      setOfficeObject({label: props.data.office.name, value: props.data.office.id})
      if(props.data.role === "OPERADOR")
        setRoleObject({value: "ROLE_OPERATOR", label: props.data.role})
      else if(props.data.role === "SOPORTE")
        setRoleObject({value: "ROLE_SUPPORT", label: props.data.role})
      else
        setRoleObject({value: "ROLE_DELIVERY_MAN", label: props.data.role})
      setIsUpdate(true)
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data, setValue, register])

  const getOfficeList = async () =>  {
    setIsLoadingOffices(true)
    try{
      const officeResponse = await RestService.axiosClientGet('/service/office')
      let officesList = officeResponse.data

      officesList.map(office => {
        office.value = office.id;
        office.label = office.name;
        return office;
      })
      setOfficesList(officesList)

    }catch(e){
      console.error("Error in users - getOfficeList", e)
      history.push("/logout")
    }
    setIsLoadingOffices(false)
  }


  const onSubmmit = async data => {

    setFirstLoad(false)
    if(!roleObject || !officeObject )
      return;
    var requestData = {};

    requestData.name = data["name"];
    requestData.lastName = data["lastName"];
    requestData.userName = data["userName"];
    requestData.cellPhoneNumber = data["cellPhoneNumber"];
    requestData.password = data["password"];
    requestData.role = roleObject.value;
    requestData.isEnabled = isEnabled;
    requestData.officeId = officeObject.value

    console.log("requestData", requestData)

    setIsLoading(true)
    if(isUpdate){
      requestData.id = props.data.id
      if(props.dataFromParent.isOnlyForDeliveryUsers){
        await RestService.axiosClientPut('/service/users_delivery', requestData, 'usuario')
      }else{
        await RestService.axiosClientPut('/service/users_administration', requestData, 'usuario')
      }
    }else{
      if(props.dataFromParent.isOnlyForDeliveryUsers){
        await RestService.axiosClientPost('/service/users_delivery', requestData, 'usuario')
      }else{
        await RestService.axiosClientPost('/service/users_administration', requestData, 'usuario')
      }   
    }
    setIsLoading(false)
    setFirstLoad(true)
    props.closeModal()
    setContext({reloadUsersList: !context.reloadUsersList})
  }


  return(
    <Form onSubmit={handleSubmit(onSubmmit)}>

      <Form.Group>
        <h3 className="text-primary">{isUpdate ? 'Actualizar': 'Registrar'} Usuario</h3>
        <hr/>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={3}>Nombres</Form.Label>
        <Col sm={9}>
          <Form.Control 
            ref={register({
              required: "Ingrese el nombre del usuario",
              pattern: {
                value: /^[a-zA-Z\s]*$/i,
                message: "El nombre no puede contener números o caracteres especiales"
              },
              maxLength: { value: 25, message: "El nombre es demasiado largo" },
              minLength: { value: 3, message: "El nombre es demasiado corto" }
            })}

            placeholder="Nombre" 
            type="text" 
            name="name"/>
        </Col>
      </Form.Group>

      {errors.name && <AlertForm message={errors.name.message}/>}

      <Form.Group as={Row}>
        <Form.Label column sm={3}>Apellidos</Form.Label>
        <Col sm={9}>
          <Form.Control 
            ref={register({
              required: "Ingrese los apellidos del usuario",
              pattern: {
                value: /^[a-zA-Z\s]*$/i,
                message: "El apellido no puede contener números o caracteres especiales"
              },
              maxLength: { value: 25, message: "El apellido es demasiado largo" },
              minLength: { value: 3, message: "El apellido es demasiado corto" }
            })}
            placeholder="Apellidos" 
            type="text" 
            name="lastName"/>
        </Col>
      </Form.Group>


      {errors.lastName && <AlertForm message={errors.lastName.message}/>}

      <Form.Group as={Row}>
        <Form.Label column sm={3}>Selecciona el Rol</Form.Label>
        <Col  sm={9}>
            <Select 
              placeholder={"Seleccione el rol"}
              value={roleObject}
              style={{ zIndex:"99999 !important"}} 
              options={selectOptions}  
              onChange={opt =>  opt.value ? setRoleObject({label: opt.label, value: opt.value}) : setRoleObject(null)}
            /> 
        </Col>
      </Form.Group>

      {!roleObject && !firstLoad &&  <AlertForm message={"Debe Seleccionar un Rol"}/>}


      <Form.Group as={Row}>
        <Form.Label column sm={3}>Habilitar</Form.Label>
        <Col sm={9}>
          <Checkbox checked={isEnabled} onChange={() => setIsEnabled(!isEnabled)}>
            <Tag color={isEnabled ? "green" : "volcano"}>
              {isEnabled ? "Habilitado" : "Deshabilitado"}
            </Tag>
          </Checkbox>
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={3}>Correo Electrónico</Form.Label>
        <Col sm={9}>
          <Form.Control 
            ref={register({
              required: "El correo electrónico es obligatorio",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: "Correo electrónico no válido"
              }
            })}
            name="userName" 
            type="text" 
            className="form-control" 
            placeholder="Correo Electrónico" />
        </Col>
      </Form.Group>

      {errors.userName && <AlertForm message={errors.userName.message}/>}

      <Form.Group as={Row}>
        <Form.Label column sm={3}>Número Celular</Form.Label>
        <Col sm={9}>
          <Form.Control 
            ref={register({
              required: "El número celular es obligatorio",
              pattern: {
                value: /^[0-9]*$/i,
                message: "Número de celular no válido"
              },
              maxLength: { value: 10, message: "El número es demasiado largo" },
              minLength: { value: 10, message: "El número es demasiado corto" }
            })}
            name="cellPhoneNumber" 
            type="text" 
            className="form-control" 
            placeholder="Número Celular" />
        </Col>
      </Form.Group>

      {errors.cellPhoneNumber && <AlertForm message={errors.cellPhoneNumber.message}/>}

      <Form.Group as={Row}>
        <Form.Label column sm={3}>Selecciona el asadero</Form.Label>
        <Col className={"z"}  sm={9}>
          {isLoadingOffices ? 
            <Spin className="float-right" size="large" /> 
          :( 
            <Select 
              placeholder={"Seleccionar el asadero"}
              value={officeObject}
              style={{ zIndex:"99999 !important"}} 
              options={officesList}  
              onChange={opt =>  opt.value ? setOfficeObject({label: opt.label, value: opt.value}) : setOfficeObject(null)}
            /> 
          )}
        </Col>
      </Form.Group>

      {!officeObject && !firstLoad &&  <AlertForm message={"Debe Seleccionar un asadero"}/>}
      
      {isUpdate &&
      <Form.Group as={Row}>
        <Form.Label column sm={3}>Actualizar contraseña</Form.Label>
        <Col sm={9}>
          <Checkbox checked={isUpdatePassword} onChange={() => setIsUpdatePassword(!isUpdatePassword)}>
            <Tag color={isUpdatePassword ? "green" : "volcano"}>
              {isUpdatePassword ? "Actualizar" : "No Actualizar"}
            </Tag>
          </Checkbox>
        </Col>
      </Form.Group>
      }

      { (!isUpdate || isUpdatePassword) &&
      <Form.Group as={Row}>
        <Form.Label column sm={3}>Contraseña</Form.Label>
        <Col sm={9}>
          <Form.Control 
            ref={register({
              required: "La Contraseña es obligatoria",
            })}
            name="password" 
            type="password" 
            className="form-control" 
            placeholder="Contraseña" />
        </Col>
      </Form.Group>
      }

      { (!isUpdate || isUpdatePassword) && errors.password && <AlertForm message={errors.password.message} />}
      

      <Form.Group>
        <Col sm={12}>
          {isLoading ? 
            <Spin className="float-right" size="large" /> 
          : 
            <Button variant="primary" className="float-right" type="submit">
              {isUpdate ? 'Actualizar': 'Registrar'} Usuario
            </Button>
          }
        </Col>
      </Form.Group>
    </Form>
  ) 
}

export default Users
