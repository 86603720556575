import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import DownloadOrderReport from "./DownloadOrderReport";
import { Modal, Container, Button } from "react-bootstrap";
import { CloseCircleOutlined } from "@ant-design/icons";
import DatePicker from "react-datepicker";
import locale_es from "date-fns/locale/es";
import { Row, Col, Table, Spin } from "antd";
import Select from "react-select";
import moment from "moment/min/moment-with-locales";
//import moment from 'moment';
import UserReport from "./UserDetail";
import swal from 'sweetalert';
import "./OrderReport.css";
import * as RestService from '../../services/RestService';

moment.locale("es");

const OrderReport = () => {

  const history = useHistory();

  const [data, setData] = useState([]);
  const [showModalCharts, setShowModalCharts] = useState(false);
  const [initialDate, setInitialDate] = useState(new Date());
  const [finalDate, setFinalDate] = useState(new Date());
  const [officesList, setOfficesList] = useState([]);
  const [isLoadingOffices, setIsLoadingOffices] = useState(false);
  const [isLoadingReport, setIsLoadingReport] = useState(false);
  const [officeObject, setOfficeObject] = useState(null);

  const [totalId, setTotalId] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalDiscounts, setTotalDiscounts] = useState(0);
  const [totalShippingRevenue, setTotalShippingRevenue] = useState(0);
  const [totalAdministrativeCharge, setTotalAdministrativeCharge] = useState(0);
  const [averageRank, setAverageRank] = useState(0);

  useEffect(() => {
    const getOfficeList = async () =>  {
      setIsLoadingOffices(true)
      try{
        const officeResponse = await RestService.axiosClientGet('/service/office')
        let officesList = officeResponse.data
        officesList.map(office => {
          office.value = office.id;
          office.label = office.name;
          return office;
        })
        officesList.unshift({value: "ALL", label: "Todos los Asaderos"})
        setOfficesList(officesList)
  
      }catch(e){
        console.error("Error in OrderReport - getOfficeList", e)
        history.push("/logout")
      }
      setIsLoadingOffices(false)
    }
    getOfficeList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = async () => {
    if(!initialDate){
      swal("Información", `Debes seleccionar la fecha inicial`, "info",{button:  "Aceptar"});
      return;
    }
    if(!finalDate){
      swal("Información", `Debes seleccionar la fecha final`, "info",{button:  "Aceptar"});
      return;
    }
    if(!officeObject){
      swal("Información", `Debes seleccionar el asadero`, "info",{button:  "Aceptar"});
      return;
    }
    if(initialDate > finalDate){
      swal("Información", `La fecha inicial debe ser menor a la fecha final`, "info",{button:  "Aceptar"});
    }
    await getReportData();
  }

  const getReportData = async () =>  {

    let iD = moment(initialDate).toDate();
    iD.setHours(0);
    iD.setMinutes(0);
    iD.setSeconds(0);

    let fD = moment(finalDate).toDate();
    fD.setHours(23);
    fD.setMinutes(59);
    fD.setSeconds(59);

    setIsLoadingReport(true)
    try{
      let officeId = officeObject.value;
      if(officeId ==="ALL"){
        officeId = null;
      }
      const ordersResponse = await RestService.axiosClientPost('/service/order/order_report',{
        initialDate: moment(iD).format("DD/MM/YYYY HH:mm"), 
        finalDate: moment(fD).format("DD/MM/YYYY HH:mm"), 
        officeId
      }, null, true)
      let orderReport = ordersResponse.data
      orderReport.map(order => {
        order.key = order.id
      })
      setData(orderReport);
      calculateData(orderReport);

    }catch(e){
      console.error("Error in OrderReport - getReportData", e)
      history.push("/logout")
    }
    setIsLoadingReport(false)
  }

  const calculateData = (orderReport) => {
    setTotalId(orderReport.length);
    setTotalPrice(Number((sumPropInArray(orderReport, 'totalPrice')).toFixed(1)).toLocaleString());
    setTotalDiscounts(Number((sumPropInArray(orderReport, 'totalCouponDiscount')).toFixed(1)).toLocaleString());
    setTotalShippingRevenue(sumPropInArray(orderReport, 'shippingRevenue'));
    setTotalAdministrativeCharge(sumPropInArray(orderReport, 'administrativeCharge'));
    setAverageRank(Math.round(sumPropInArray(orderReport, 'rank') / orderReport.length * 100)/ 100);
  }

  const sumPropInArray = (array, prop) => {
    var total = 0
    for ( var i = 0, _len = array.length; i < _len; i++ ) {
        total += array[i][prop]
    }
    return total
  }

  const columns = [
    {
      title: "Consecutivo",
      dataIndex: "id",
      key: "id",
      width: 110,
      fixed: "left",
    },
    {
      title: "Fecha",
      dataIndex: "date",
      key: "date",
      width: 150,
      render: (text, record) => (<p>{moment(text).format("DD/MM/YYYY HH:mm:ss")}</p>),
    },
    {
      title: "Punto de venta",
      dataIndex: "office",
      key: "office",
      width: 150,
      render: (text, record) => (
        <p>{text.name}</p>
      ),
    },
    {
      title: "Nombre completo",
      dataIndex: "user",
      key: "user",
      width: 150,
      render: (text, record) => (<p>{`${text.name} ${text.lastName}`}</p>),
    },
    {
      title: "Correo",
      dataIndex: "user",
      key: "user",
      width: 250,
      render: (text, record) => (<p>{text.userName}</p>),
    },
    {
      title: "Celular",
      dataIndex: "user",
      key: "user",
      width: 150,
      render: (text, record) => (<p>{text.cellPhoneNumber}</p>),
    },
    {
      title: "Costo de productos",
      dataIndex: "subtotalPrice",
      key: "subtotalPrice",
      width: 150,
      render: (text, record) => (<p>{text - (record.shippingCost + record.shippingRevenue+ record.tip + record.administrativeCharge)}</p>),
    },
    {
      title: "Tarifa de Servicio",
      dataIndex: "administrativeCharge",
      key: "administrativeCharge",
      width: 150,
    },
    {
      title: "Total Cobro envío",
      dataIndex: "shippingRevenue",
      key: "shippingRevenue",
      width: 150,
      render: (text, record) => (<p>{text + record.shippingCost}</p>),
    },
    {
      title: "propina",
      dataIndex: "tip",
      key: "tip",
      width: 100,
    },

    {
      title: "Descuento por cupon",
      dataIndex: "totalCouponDiscount",
      key: "totalCouponDiscount",
      width: 150,
    },

    {
      title: "Total Pago Cliente",
      dataIndex: "subtotalPrice",
      key: "subtotalPrice",
      width: 150,
    },
    {
      title: "Valor envío brosty",
      dataIndex: "shippingCost",
      key: "shippingCost",
      width: 150,
    },
    {
      title: "Utilidad APP",
      dataIndex: "shippingRevenue",
      key: "shippingRevenue",
      width: 100,
      render: (text, record) => (<p>{text + record.administrativeCharge}</p>),
    },
    {
      title: "calificacion",
      dataIndex: "rank",
      key: "rank",
      width: 110,
    },
  ];

  const getTitle = () => {
    return (
      <div style={{padding: "10px"}}>
        <Row justify="space-around" align="middle">
          <Col span={5}>
            <span> Fecha Inicial </span>
            <DatePicker
              onChange={(val) => setInitialDate(val)}
              locale={locale_es}
              name="initialDate"
              disabledKeyboardNavigation
              dateFormat="d/MM/yyyy"
              className={"order-report form-control"}
              placeholderText="Fecha inicial"
              autoComplete="off"
              selected={initialDate}
            />
          </Col>
          <Col span={5}>
            <span> Fecha Final </span>
            <DatePicker
              onChange={(val) => setFinalDate(val)}
              locale={locale_es}
              name="initialDate"
              disabledKeyboardNavigation
              dateFormat="d/MM/yyyy"
              className={"order-report form-control"}
              placeholderText="Fecha Final"
              autoComplete="off"
              selected={finalDate}
            />
          </Col>
          <Col span={6}>
            <span> Asadero </span>
            {isLoadingOffices ? (
              <Spin className="float-right" size="large" />
            ) : (
              <Select
                placeholder={"Seleccionar Asadero"}
                value={officeObject}
                style={{ zIndex: "99999 !important" }}
                options={officesList}
                onChange={(opt) =>
                  opt.value
                    ? setOfficeObject({ label: opt.label, value: opt.value })
                    : setOfficeObject(null)
                }
              />
            )}
          </Col>
          <Col span={3}>
            <Button variant="info" className="float-right" onClick={handleSearch}>
            🔎   Buscar
            </Button>
           
          </Col>
          <Col span={3}>
            <DownloadOrderReport data={data}/>
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <div>
      {getTitle()}
      <Table
        loading={isLoadingReport}
        columns={columns}
        dataSource={data}
        pagination={{ pageSize: 30 }}
        scroll={{ x: 500, y: 350 }}
        showSorterTooltip={false}
        locale={{
          filterConfirm: "Aceptar",
          filterReset: "Reiniciar",
          emptyText: "No se han encontrado datos",
        }}
      />

        <Row>
          <Col span={4}>
            <h6 style={{ float: "left" }}>
              {`Total Consecutivos: ${totalId}`}
            </h6>
          </Col>
          <Col span={5}>
            <h6 style={{ float: "left" }}>
              {`Valor Total: ${totalPrice}`}
            </h6>
          </Col>
          <Col span={5}>
            <h6 style={{ float: "left" }}>
              {`Total Descuentos: ${totalDiscounts}`}
            </h6>
          </Col>
          <Col span={5}>
            <h6 style={{ float: "left" }}>
              {`Total Utilidad: ${Number((totalShippingRevenue + totalAdministrativeCharge).toFixed(1)).toLocaleString()}`}
            </h6>
          </Col>
          <Col span={4}>
            <h6 style={{ float: "left" }}>
              {`Promedio Calificación: ${averageRank}`}
            </h6>
          </Col>
          
        </Row>

      <Modal size="lg" show={showModalCharts} onHide={() => setShowModalCharts(false)}>
        <Container style={{padding: "20px"}}>
          <CloseCircleOutlined  className="float-right" onClick={() => setShowModalCharts(false)} style={{ fontSize: '25px', color: '#08c' }}/>
          <UserReport />
        </Container>
      </Modal>


    </div>
  );
};

export default OrderReport;
