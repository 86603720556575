import React, {useEffect} from "react";
import ReactExport from "react-data-export";
import { Button } from "react-bootstrap";
import moment from "moment/min/moment-with-locales";
//import moment from 'moment'

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const DownloadUserReport = ({data}) => {

  useEffect(() => {
  }, [data])

  return (
    <ExcelFile
      filename={"Reporte de Usuarios"}
      element={
        <Button variant="success" className="float-right">
          ⬇️ Excel
        </Button>
      }
    >
      <ExcelSheet data={data} name="Usuarios">
        <ExcelColumn label="ID de Usuario" value="id" />
        <ExcelColumn label="Nombre Completo" value="fullName" />
        <ExcelColumn label="Correo" value="userName" />
        <ExcelColumn label="Celular" value="cellPhoneNumber" />
      </ExcelSheet>
    </ExcelFile>
  );
};
export default DownloadUserReport;
