
import React, {useState, useEffect, useContext} from 'react'
import { Form, Button, Row, Col } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { Spin, Checkbox, Tag } from 'antd';
import AlertForm from '../utilities/AlertForm'
import * as RestService from '../../services/RestService'
import {Context} from '../../context/context'


const Category = (props) => {

  const { handleSubmit, register, errors, setValue } = useForm();
  const [isEnabled, setIsEnabled] = useState(true);
  //const [file, setFile] = useState(null);
  //const [fileLabel, setFileLabel] = useState("Cargar imagen de la categoría del producto");
  const [isLoading, setIsLoading] = useState(false);
  //const [firstLoad, setFirstLoad] = useState(true);
  const [isUpdate, setIsUpdate] = useState(false);
  const [context, setContext] = useContext(Context)

  //const buttonUploadFile = useRef();


  useEffect(() => {

    if(props.data){
      setValue("name", props.data.name)
      //setFileLabel(props.data.imagePath.split("-")[1])
      //setFile(props.data.imagePath)
      setIsEnabled(props.data.isEnabled)
      setIsUpdate(true)
    }

  }, [props.data, setValue])


  const onSubmmit = async data => {
    
    //setFirstLoad(false)
    /*if(!file)
      return;*/

    var formData = new FormData();

    formData.append('name', data["name"]);
    formData.append('isEnabled',  isEnabled);
    formData.append('file', null);

    setIsLoading(true)
    if(isUpdate){
      formData.append('id', props.data.id);
      await RestService.axiosFormdataPut('/service/product_category', formData, 'categoría')
    }else{
      formData.append('id', 1);
      await RestService.axiosFormdataPost('/service/product_category', formData, 'categoría')
    }
    setIsLoading(false)
    clearFormFields()
    props.closeModal()
    setContext({reloadCategoryProductList: !context.reloadCategoryProductList})

  }


  /*const handlerFile = (e) => {

    if(buttonUploadFile.current && buttonUploadFile.current.files && buttonUploadFile.current.files[0] ){
      setFileLabel(buttonUploadFile.current.files[0].name)
      setFile(buttonUploadFile.current.files[0])
    }else{
      setFileLabel("Cargar imagen de la categoría del producto");
      setFile(null)
    }
  }*/

  const clearFormFields = () => {
    //setFile(null)
    //setFileLabel("Cargar imagen de la categoría del producto");
    //setFirstLoad(true)
  }

  return(
      <Form onSubmit={handleSubmit(onSubmmit)}>

      <Form.Group>
        <h3 className="text-primary">{isUpdate ? 'Actualizar': 'Registrar'} Categoría del producto</h3>
        <hr/>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={2}>Nombre</Form.Label>
        <Col sm={10}>
          <Form.Control 
            ref={register({
              required: "Ingrese un nombre a la categoría",
              maxLength: 20
            })}
            as="input" 
            placeholder="Nombre" 
            name="name"/>
        </Col>
      </Form.Group>

      {errors.name && errors.name.type === "required" && <AlertForm message={errors.name.message}/>}
      {errors.name && errors.name.type === "maxLength" && <AlertForm message={"El número máximo de caracteres es de 20"}/>}

      <Form.Group as={Row}>
        <Form.Label column sm={2}>Habilitar</Form.Label>
        <Col sm={10}>
          <Checkbox checked={isEnabled} onChange={() => setIsEnabled(!isEnabled)}>
            <Tag color={isEnabled ? "green" : "volcano"}>
              {isEnabled ? "Habilitado" : "Deshabilitado"}
            </Tag>
          </Checkbox>
        </Col>
      </Form.Group>

{/*
      <Form.Group as={Row}>
        <Form.Label column sm={2}>Cargar imagen de la categoría del producto</Form.Label>
          <Col sm={10}>
            <Form.File 
              id="custom-file-translate-html"
              label={fileLabel}
              data-browse="Cargar imagen"
              custom
              ref={buttonUploadFile}
              name="file"
              onChange={handlerFile}
            />
          </Col>
      </Form.Group>

      {!file && !firstLoad &&  <AlertForm message={"La imagen es obligatoria"}/>}
      */
}

      <Form.Group>
        <Col sm={12}>
          {isLoading ? 
            <Spin className="float-right" size="large" /> 
          : 
            <Button variant="primary" className="float-right" type="submit">
              {isUpdate ? 'Actualizar': 'Registrar'} Categoría
            </Button>
          }
        </Col>
      </Form.Group>
    </Form>
  )  
}

export default Category