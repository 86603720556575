import cookie from 'js-cookie'

export const isAuth = () => {
  const token = cookie.get("token")
  return !(token === undefined || token === '')
}

export const hasRole = (_roles) =>{
  const rolesCookie = cookie.get("roles")
  let hasRole = false
  if(rolesCookie !== undefined){
    const roles = JSON.parse(cookie.get("roles"))
    if(_roles !== undefined)
      roles.forEach(role => { if(_roles.includes(role)) hasRole = true})
  }
  return hasRole;
}

export const getUserFromCookies = () => {
  const user = {
    fullName: cookie.get("fullName"),
    name: cookie.get("name"),
    lastName: cookie.get("lastName"),
    userName: cookie.get("userName"),
    cellPhoneNumber: cookie.get("cellPhoneNumber"),
    id: cookie.get("id"),
    uuid: cookie.get("uuid")
  }
  return user
}