
import React, {useState, useEffect, useRef, useContext} from 'react'
import { Form, Button, Row, Col } from 'react-bootstrap';

import { useForm } from "react-hook-form";
import { Spin, Checkbox, Tag } from 'antd';
import AlertForm from '../utilities/AlertForm'
import * as RestService from '../../services/RestService'
import {Context} from '../../context/context'
import {imageSizeExceedLimit} from '../../services/FileService';

const Office = (props) => {

  const { handleSubmit, register, errors, setValue } = useForm();
  const [file, setFile] = useState(null);
  const [fileLabel, setFileLabel] = useState("Cargar imagen del asadero");
  const [isLoading, setIsLoading] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [isUpdate, setIsUpdate] = useState(false);
  const [context, setContext] = useContext(Context)
  const [fileSizeExceedLimit, setFileSizeExceedLimit] = useState(false);
  const [isEnabled, setIsEnabled] = useState(true);

  const buttonUploadFile = useRef();


  useEffect(() => {

    if(props.data){
      setValue("name", props.data.name)
      setValue("address", props.data.address)
      setValue("phone", props.data.phone)
      setValue("latitude", props.data.latitude)
      setValue("longitude", props.data.longitude)
      setValue("municipality", props.data.municipality)
      setIsEnabled(props.data.isEnabled)
      setFileLabel(props.data.imagePath.split("-")[1])
      setFile(props.data.imagePath)
      setIsUpdate(true)
    }

  }, [props.data, setValue])


  const onSubmmit = async data => {
    
    setFirstLoad(false)
    if(!file || imageSizeExceedLimit(file, 60, setFileSizeExceedLimit))
      return;

    var formData = new FormData();

    formData.append("name", data["name"]);
    formData.append("address", data["address"]);
    formData.append("phone", data["phone"]);
    formData.append("latitude", data["latitude"]);
    formData.append("longitude", data["longitude"]);
    formData.append("municipality", data["municipality"]);
    formData.append('isEnabled',  isEnabled);
    formData.append('file', file);

    setIsLoading(true)
    if(isUpdate){
      formData.append('id', props.data.id);
      await RestService.axiosFormdataPut('/service/office', formData, 'asadero')
    }else{
      await RestService.axiosFormdataPost('/service/office', formData, 'asadero')
    }
    setIsLoading(false)
    clearFormFields()
    props.closeModal()
    setContext({reloadOfficeList: !context.reloadOfficeList})

  }


  const handlerFile = (e) => {

    if(buttonUploadFile.current && buttonUploadFile.current.files && buttonUploadFile.current.files[0] ){
      setFileLabel(buttonUploadFile.current.files[0].name)
      setFile(buttonUploadFile.current.files[0])
    }else{
      setFileLabel("Cargar imagen del asadeto");
      setFile(null)
    }
  }

  const clearFormFields = () => {
    setFile(null)
    setFileLabel("Cargar imagen del asadero");
    setFirstLoad(true)
  }


  return(
      <Form onSubmit={handleSubmit(onSubmmit)}>

      <Form.Group>
        <h3 className="text-primary">{isUpdate ? 'Actualizar': 'Registrar'} Asaderos</h3>
        <hr/>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={2}>Nombre</Form.Label>
        <Col sm={10}>
          <Form.Control 
            ref={register({
              required: "Ingrese el nombre del asadero",
            })}
            as="input" 
            placeholder="Nombre" 
            name="name"/>
        </Col>
      </Form.Group>

      {errors.name && <AlertForm message={errors.name.message}/>}

      <Form.Group as={Row}>
        <Form.Label column sm={2}>Dirección</Form.Label>
        <Col sm={10}>
          <Form.Control 
            ref={register({
              required: "Ingrese la dirección del asadero",
            })}
            as="input" 
            placeholder="Dirección" 
            name="address"/>
        </Col>
      </Form.Group>

      {errors.address && <AlertForm message={errors.address.message}/>}

      <Form.Group as={Row}>
        <Form.Label column sm={2}>Teléfono</Form.Label>
        <Col sm={10}>
          <Form.Control 
            ref={register({
              required: "Ingrese el teléfono del asadero",
            })}
            as="input" 
            placeholder="Teléfono" 
            name="phone"/>
        </Col>
      </Form.Group>

      {errors.phone && <AlertForm message={errors.phone.message}/>}

      <Form.Group as={Row}>
        <Form.Label column sm={2}>Latitud</Form.Label>
        <Col sm={10}>
          <Form.Control 
            ref={register({
              required: "Ingrese la latitud del asadero",
            })}
            as="input" 
            placeholder="Latitud" 
            name="latitude"/>
        </Col>
      </Form.Group>

      {errors.latitude && <AlertForm message={errors.latitude.message}/>}

      <Form.Group as={Row}>
        <Form.Label column sm={2}>longitud</Form.Label>
        <Col sm={10}>
          <Form.Control 
            ref={register({
              required: "Ingrese la longitud del asadero",
            })}
            as="input" 
            placeholder="Longitud" 
            name="longitude"/>
        </Col>
      </Form.Group>

      {errors.longitude && <AlertForm message={errors.longitude.message}/>}

      <Form.Group as={Row}>
        <Form.Label column sm={2}>Municipio</Form.Label>
        <Col sm={10}>
          <Form.Control 
            ref={register({
              required: "Ingrese el municipio del asadero",
            })}
            as="input" 
            placeholder="Municipio" 
            name="municipality"/>
        </Col>
      </Form.Group>

      {errors.municipality && <AlertForm message={errors.municipality.message}/>}

      <Form.Group as={Row}>
        <Form.Label column sm={2}>Habilitar</Form.Label>
        <Col sm={10}>
          <Checkbox checked={isEnabled} onChange={() => setIsEnabled(!isEnabled)}>
            <Tag color={isEnabled ? "green" : "volcano"}>
              {isEnabled ? "Habilitado" : "Deshabilitado"}
            </Tag>
          </Checkbox>
        </Col>
      </Form.Group>
   
      <Form.Group as={Row}>
        <Form.Label column sm={2}>Cargar imagen del asadero</Form.Label>
          <Col sm={10}>
            <Form.File 
              id="custom-file-translate-html"
              label={fileLabel}
              data-browse="Cargar imagen"
              custom
              ref={buttonUploadFile}
              name="file"
              onChange={handlerFile}
            />
          </Col>
      </Form.Group>

      {!file && !firstLoad &&  <AlertForm message={"La imagen es obligatoria"}/>}
      {fileSizeExceedLimit && !firstLoad &&  <AlertForm message={"El tamaño máximo de la imagen debe ser 60 KB"}/>}


      <Form.Group>
        <Col sm={12}>
          {isLoading ? 
            <Spin className="float-right" size="large" /> 
          : 
            <Button variant="primary" className="float-right" type="submit">
              {isUpdate ? 'Actualizar': 'Registrar'} Asadero
            </Button>
          }
        </Col>
      </Form.Group>
    </Form>
  )  
}

export default Office;