import React, { useEffect, useState } from 'react'
import { Steps } from 'antd';
import { AlertTwoTone, CheckOutlined, SmileOutlined } from '@ant-design/icons';
import { FaMotorcycle } from "react-icons/fa";
import { GiChickenOven } from "react-icons/gi";

const { Step } = Steps;
const OrderState = ({ data, closeModal }) => {

  const [current, setCurrent] = useState(0)
  //const [nextState, setNextState] = useState('')
  //const [buttonName, setButtonName] = useState(0)

  useEffect(() => {
    const getDataFromState = () => {
      const { state } = data
      if (state === "REQUESTED" || state === "REQUESTED_STORE_PICKUP") {
        setCurrent(0);
        //setNextState("CONFIRMED");
        //setButtonName("confirmado")
      } else if (state === "CONFIRMED" || state === "CONFIRMED_STORE_PICKUP") {
        setCurrent(1);
        //setNextState("IN_DELIVERY");
        //setButtonName("En entrega")
      } else if (state === "IN_DELIVERY" || state === "PREPARED_STORE_PICKUP") {
        setCurrent(2);
        //setNextState("DELIVERED");
        //setButtonName("Entregado")
      } else {
        setCurrent(3);
      }
    }

    getDataFromState()
  }, [data])



  return (
    <div>
      {
        !data.storePickup ?
          <Steps direction="vertical" current={current}>
            <Step title="Solicitado" description="El pedido ha sido solicitado por el cliente" icon={<AlertTwoTone />} />
            <Step title="Confirmado" description="Brosty ha confirmado el pedido" icon={<CheckOutlined />} />
            <Step title="En entrega" description="El domicialirio está en camino" icon={<FaMotorcycle />} />
            <Step title="Entregado" description="El usuario ha recibido el pedido!" icon={<SmileOutlined />} />
          </Steps>
          :
          <Steps direction="vertical" current={current}>
            <Step title="Solicitado para recoger en punto de venta" description="El pedido ha sido solicitado por el cliente" icon={<AlertTwoTone />} />
            <Step title="Confirmado" description="Brosty ha confirmado el pedido" icon={<CheckOutlined />} />
            <Step title="Preparado" description="El pedido esta preparado y listo para ser recogido por el cliente" icon={<GiChickenOven />} />
            <Step title="Entregado" description="El usuario ha recibido el pedido en el punto de venta!" icon={<SmileOutlined />} />
          </Steps>
      }


      {/*current !== 3 && 
        <Button variant="primary" className="float-right" onClick={changeState}>
                {`Actualizar estado a ${buttonName}`}
        </Button>
      */}
    </div>
  )

}

export default OrderState;