import React, { useState, useEffect } from "react";
import { fire } from "../../config/firebase";
import { Row, Col, Card , Alert} from "react-bootstrap";
import { Button, notification } from 'antd';
import { ChatList } from "react-chat-elements";
import ChatMessages from "./ChatMessages";
import ReactTimeAgo from 'react-time-ago'
import { WechatOutlined } from '@ant-design/icons';


import "./Chat.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-chat-elements/dist/main.css";

function App() {
  const [currentUser, setCurrentUser] = useState();
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const fireListening = fire.database().ref("users/");
    fireListening.on("value", (snapshot) => {
      setMessagesFromFirebase(snapshot)
    });

    return function cleanup() {
      fireListening.off();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const setMessagesFromFirebase = (snapshot) => {
    const currentMessages = snapshot.val();
    if (currentMessages != null) {
      let currentMessagesSorted = Object.values(currentMessages).sort(function(a,b){
        return new Date(b.timestampLastMessage) - new Date(a.timestampLastMessage);
      });
      setMessages(currentMessagesSorted);
      if(currentUser && currentMessagesSorted[0].unReadMessage !== 0 ){
				if(currentMessagesSorted[0].lastMessageSendBy !== 'BROSTY'){
					openNotification(currentMessagesSorted[0].user.fullName, currentMessagesSorted[0].lastMessage)
				}
        if(currentMessagesSorted[0].user._id === currentUser.user._id){
          fire.database().ref(`users/${currentUser.user._id}/`).update({unReadMessage: 0});
        }
      }
    }
  }

  const openNotification = (title, description) => {
    notification.open({
      message: title,
      description: description,
      icon: <WechatOutlined style={{ color: '#108ee9' }} />,
      duration: 5,
      placement: 'bottomRight',
  
    });
  };

  const onClickChatList = (_message) => {
    setCurrentUser(_message);
    const message = {
      unReadMessage: 0,
    };
    fire.database().ref(`users/${_message.user._id}/`).update(message);
  }




  const currentMessages = messages.map((message, i) => {
    return (
      <ChatList
        key={i}
        onClick={() => onClickChatList(message)}
        dataSource={[
          {
            title: message.user.fullName,
            subtitle: `Último mensaje: ${message.lastMessage}`,
            unread: message.unReadMessage,
            dateString: (<ReactTimeAgo date={message.timestampLastMessage} locale="es"/>),
            onContextMenu: (() => console.log("onContextMenu"))
          },
        ]}
        
      />
    );
  }, []);

  return (
    <Row style={{width: '100%'}}>
      <Col sm={4} className="pr-1">
        <Card className="text-center card-custom h-60">
          <Card.Header className="h-10 card-custom">
          <Alert  variant="primary">
            Chats
          </Alert>
          </Card.Header>
          <Card.Body className="card-body-custom overflow-auto">
            {currentMessages}
          </Card.Body>
        </Card>
      </Col>
      <Col sm={8} className="pl-1">
        {
          currentUser && <ChatMessages currentUser={currentUser}></ChatMessages>
        }
      </Col>
    </Row>
  );
}

export default App;
