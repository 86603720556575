import React, {useEffect, useContext} from 'react'
import { fire } from "../../config/firebase";
//import firebase from 'firebase'; // 4.8.1
import cookie from 'js-cookie';
import * as RestService from '../../services/RestService'
import {Context} from '../../context/context'

import { Button, notification } from 'antd';
import { FileDoneOutlined } from '@ant-design/icons';
import OrderList from "./OrderList"
const Order = () => {
	
  const [context, setContext] = useContext(Context);
  const audio = new Audio("https://notificationsounds.com/storage/sounds/file-sounds-897-alarm-frenzy.mp3")
  
  useEffect(() => {
    requestPermission();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const requestPermission = async () => {
    try{

      const firebaseTokenNotification = cookie.get("firebase-token-notification")
      const firebaseMessaging = fire.messaging();
      await firebaseMessaging.requestPermission();
      let token = await firebaseMessaging.getToken();

      if(firebaseTokenNotification !== token){
        sendFirebaseTokenNotificationToServer(token)
      }

      firebaseMessaging.onMessage((payload) => {
				openNotification(payload.notification.title, payload.notification.body)
				setContext({reloadOrderList: Math.random()})
      })

      firebaseMessaging.onTokenRefresh(async () => {
        try{
          let token = await firebaseMessaging.getToken();
          await sendFirebaseTokenNotificationToServer(token);
        }catch(error){
          console.error("Error in update token firebase", error)
        }
      })
    }catch(err) {
      console.error("dont have permission:", err)
    }
  }

  const sendFirebaseTokenNotificationToServer = async(token) => {

    const userId = cookie.get("id");
    var requestData = {};
    requestData.token = token;
    requestData.userId = userId;
    RestService.axiosClientPost("/service/firebase_token_notification", requestData, 'token_notification', true);
    cookie.set("firebase-token-notification", token);
  }

const openNotification = (title, description) => {
  notification.open({
    message: title,
    description: description,
    icon: <FileDoneOutlined style={{ color: '#108ee9' }} />,
    duration: 10,
    placement: 'bottomRight',

  });
  audio.play();
};

  return (
    <OrderList/>
  )
}

export default Order