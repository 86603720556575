import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import * as AuthService from '../../services/AuthService'

const PrivateRoute = ({ component: Component, ...rest }) => {

  const isAuthorized = AuthService.hasRole(rest.roles)

  return (
    <Route
      {...rest}
      render={props =>
        AuthService.isAuth() && isAuthorized ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        )
      }
    />
  )
}

export default PrivateRoute