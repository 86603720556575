import React, {useEffect, useState, useContext} from 'react'
import { useHistory } from 'react-router-dom'
import * as RestService from '../../services/RestService'
import Users from './Users'
import CustomTable from '../utilities/CustomTable'
import {Context} from '../../context/context'
import { Tag } from 'antd';

const UsersList = ({isOnlyForDeliveryUsers}) => {

  const history = useHistory()
  const [usersList, setUserList] = useState([])
  const [loadingTable, setLoadingTable] = useState(true)
  const [context, ] = useContext(Context)

  useEffect(() => {

    console.log("isOnlyForDeliveryUsers: ", isOnlyForDeliveryUsers);

    const apiRequest = async () =>  {
      setLoadingTable(true)
      try{
        let usersResponse;
        if(isOnlyForDeliveryUsers){
          usersResponse = await RestService.axiosClientGet('/service/users/delivery_man')
        }else{
          usersResponse = await RestService.axiosClientGet('/service/users_administration')
        }
       
        let usersList = usersResponse.data
        usersList.map(user => {
          user.key = user.id;
          if(user.roles[0].name === "ROLE_OPERATOR")
            user.role = "OPERADOR"
          else if(user.roles[0].name === "ROLE_SUPPORT")
            user.role = "SOPORTE"
          else 
            user.role = "DOMICILIARIO"
          return user;
        })
        setUserList(usersList)
      }catch(e){
        console.error("Error in UsersList", e)
        history.push("/logout")
      }
      setLoadingTable(false)
    }

    apiRequest()

 }, [ history, context.reloadUsersList])

 const columns = [
  {
    title: 'Nombre de Usuario',
    dataIndex: 'userName',
    key: 'userName',
    width: '25%',
    isSearchable: true
  },
  {
    title: 'Nombre',
    dataIndex: 'fullName',
    key: 'fullName',
    width: '25%',
    isSearchable: true
  },
  {
    title: 'Número Celular',
    dataIndex: 'cellPhoneNumber',
    key: 'cellPhoneNumber',
    width: '10%',
    isSearchable: true
  },
  {
    title: 'Rol',
    dataIndex: 'role',
    key: 'role',
    width: '11%',
    isSearchable: true
  },
  {
    title: 'Asadero',
    dataIndex: 'office',
    key: 'office',
    width: '12%',
    render: (text, record) => (
      <p>{`${text.name}`}</p>
      ),
	},
  {
    title: 'Estado',
    width: '8%',
    key: 'isEnabled',
    dataIndex: 'isEnabled',
    filters: [
      {
        text: 'Habilitado',
        value: true,
      },
      {
        text: 'Deshabilitado',
        value: false,
      },
    ],
    onFilter: (value, record) => record.isEnabled === value,
    render: (text, record) => (
      <Tag color={record.isEnabled ? "green" : "volcano"}>
        {record.isEnabled ? "Habilitado" : "Deshabilitado"}
      </Tag>
      )
  },
  {
    title: 'Actualizar',
    width: '9%',
    key: 'action',
    actionType: 'update',
    align: 'center'
  },
];

return(
  <CustomTable 
    loadingTable={loadingTable} 
    columns={columns} 
    dataSource={usersList} 
    component={Users}
    dataFromParent={{isOnlyForDeliveryUsers}}
    title={ <h3 className="text-primary">Usuarios</h3> }
    buttonText={"Crear Usuario"}
  />
)
}

export default UsersList