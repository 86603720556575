
import React, {useState, useEffect, useContext} from 'react'
import { Form, Button, Row, Col } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { Spin } from 'antd';
import AlertForm from '../utilities/AlertForm'
import * as RestService from '../../services/RestService'
import {Context} from '../../context/context'


const Content = (props) => {

  const { handleSubmit, register, errors, setValue } = useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [context, setContext] = useContext(Context);
  


  useEffect(() => {
    if(props.data){
      setValue('name', props.data.name);
      setValue('quantity', props.data.quantity);
      setIsUpdate(true)
    }

  }, [props, props.data, setValue])


  const onSubmmit = async data => {

    setIsLoading(true)

    var requestData = {};
    requestData.name = data["name"];
    requestData.quantity = data["quantity"];

    if(isUpdate){
      if(props.data.id){
        requestData.productId = props.dataFromParent.productId;
        requestData.id = props.data.id;
        await RestService.axiosClientPut(`/service/content/`, requestData, 'contenido')
        setContext({reloadContentList: !context.reloadContentList})
      }else{
        let contentListState = (JSON.parse(JSON.stringify(context.contentList)));
        contentListState = contentListState.filter(content => content.key !== props.data.key);
        requestData.key = Math.random()
        contentListState.push(requestData)
        setContext({contentList: JSON.parse(JSON.stringify(contentListState))})
      }

    }else{
      if(props.dataFromParent.productId){
        requestData.productId = props.dataFromParent.productId;
        await RestService.axiosClientPost(`/service/content/`, requestData, 'contenido')
        setContext({reloadContentList: !context.reloadContentList})
      }else{
        let contentListState = (JSON.parse(JSON.stringify(context.contentList)));
        requestData.key = Math.random()
        contentListState.push(requestData)
        setContext({contentList: JSON.parse(JSON.stringify(contentListState))})
      }
    }
    setIsLoading(false)
    props.closeModal()
  }




  return(
      <Form >

      <Form.Group>
        <h3 className="text-primary">{isUpdate ? 'Actualizar': 'Registrar'} Contenido</h3>
        <hr/>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={3}>Nombre</Form.Label>
        <Col sm={9}>
          <Form.Control 
            ref={register({
              required: "Ingrese el nombre",
              maxLength: 20
            })}
            as="input" 
            placeholder="Nombre" 
            name="name"/>
        </Col>
      </Form.Group>

      {errors.name && errors.name.type === "required" && <AlertForm message={errors.name.message}/>}
      {errors.name && errors.name.type === "maxLength" && <AlertForm message={"El número máximo de caracteres es de 20"}/>}

      
      <Form.Group as={Row}>
        <Form.Label column sm={3}>Cantidad</Form.Label>
        <Col sm={9}>
          <Form.Control 
            ref={register({
              required: "Ingrese la cantidad",
              pattern: {
                value: /^[0-9]*$/i,
                message: "La cantidad debe ser expresada en números"
              }
            })}
            as="input" 
            placeholder="cantidad" 
            name="quantity"/>
        </Col>
      </Form.Group>

      {errors.quantity && <AlertForm message={errors.quantity.message}/>}

      <Form.Group>
        <Col sm={12}>
          {isLoading ? 
            <Spin className="float-right" size="large" /> 
          : 
            <Button variant="primary" className="float-right" onClick={handleSubmit(onSubmmit)}>
              {isUpdate ? 'Actualizar': 'Registrar'} Contenido
            </Button>
          }
        </Col>
      </Form.Group>
    </Form>
  )  
}

export default Content