import React, {useEffect} from 'react'
import { useHistory } from 'react-router-dom'
import cookie from 'js-cookie';
import Loading from "../loading/Loading"
import { fire } from "../../config/firebase";
import * as RestService from '../../services/RestService'



const Logout = () => {

  const history = useHistory()

  useEffect(() => {

    const logout = async() => {
      await RestService.axiosClientGet('/service/user/logout');
      cookie.remove("token");
      cookie.remove("roles");
      cookie.remove("fullName");
      cookie.remove("name");
      cookie.remove("lastName");
      cookie.remove("userName");
      cookie.remove("cellPhoneNumber");
      cookie.remove("id");
      cookie.remove("uuid");
      cookie.remove("office");
      cookie.remove("refreshToken");
      fire.auth().signOut();
      history.push("/login");
    }

    logout();
    
  })


  return (
    <Loading/>
  )
}

export default Logout