import React from 'react';
import { Form, Row, Col, Alert } from 'react-bootstrap';

const AlertForm = (props) => {
  return (
    <Form.Group as={Row}>
      <Col sm={9}>
        <Alert  variant={"danger"}>{props.message}</Alert>
      </Col>
    </Form.Group>
  )
}

export default AlertForm