import React, {useEffect} from "react";
import ReactExport from "react-data-export";
import { Button } from "react-bootstrap";
import moment from "moment/min/moment-with-locales";
//import moment from 'moment'

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const DownloadOrderReport = ({data}) => {

  useEffect(() => {
   /* data.map(data =>{
      return Object.keys(data.obj).map(key => data["obj."+key] = data.obj[key])
    })
    console.log(data)*/
  }, [data])

  return (
    <ExcelFile
      filename={"Reporte de Ordenes"}
      element={
        <Button variant="success" className="float-right">
          ⬇️ Excel
        </Button>
      }
    >
      <ExcelSheet data={data} name="Ordenes">
        <ExcelColumn label="Consecutivo" value="id" />
        <ExcelColumn label="Fecha" value={(col) => (moment(col.date).format("DD/MM/YYYY HH:mm:ss"))} />
        <ExcelColumn label="Punto de Venta" value={(col) => (col.office.name)} />
        <ExcelColumn label="Nombre Completo" value={(col) => (col.user.name + " " + col.user.lastName)} />
        <ExcelColumn label="Correo" value={(col) => (col.user.userName)} />
        <ExcelColumn label="Celular" value={(col) => (col.user.cellPhoneNumber)}  />
        <ExcelColumn label="Costo de productos" value={(col) => col.subtotalPrice - (col.shippingCost + col.shippingRevenue + col.tip + col.administrativeCharge)} />
        <ExcelColumn label="Tarifa de Servicio" value="administrativeCharge" />
        <ExcelColumn label="Total Cobro Envío" value={(col) => (col.shippingRevenue + col.shippingCost)} />
        <ExcelColumn label="Propina" value="tip" />
        <ExcelColumn label="Descuento por cupon" value="totalCouponDiscount" />
        <ExcelColumn label="Total Pago Cliente" value="subtotalPrice" />
        <ExcelColumn label="Valor Envío Brosty" value="shippingCost" />
        <ExcelColumn label="Utilidad App" value={(col) => (col.shippingRevenue + col.administrativeCharge)} />
        <ExcelColumn label="Calificación" value="rank" />        
      </ExcelSheet>
    </ExcelFile>
  );
};
export default DownloadOrderReport;
