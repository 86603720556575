import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import { Table, Row, Col } from "antd";
import moment from "moment/min/moment-with-locales";
import DownloadUserReport from "./DownloadUserReport";
//import moment from 'moment';
import swal from 'sweetalert';
import "./OrderReport.css";
import * as RestService from '../../services/RestService';

moment.locale("es");

const UserReport = () => {

  const history = useHistory();

  const [data, setData] = useState([]);
  const [initialDate, setInitialDate] = useState(new Date());
  const [finalDate, setFinalDate] = useState(new Date());
  const [isLoadingReport, setIsLoadingReport] = useState(false);
  const [officeObject, setOfficeObject] = useState(null);


  useEffect(() => {
    getReportData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getReportData = async () =>  {
    setIsLoadingReport(true)
    try{
      const ordersResponse = await RestService.axiosClientGet('/service/users', null, true);
      let orderReport = ordersResponse.data
      orderReport.forEach(order => {
        order.key = order.id
      })
      setData(orderReport);

    }catch(e){
      console.error("Error in user report - getReportData", e)
      history.push("/logout")
    }
    setIsLoadingReport(false)
  }


  const columns = [
    {
      title: "ID de usuario",
      dataIndex: "id",
      key: "id",
      width: 110,
      fixed: "left",
    },
    {
      title: "Nombre Completo",
      dataIndex: "fullName",
      key: "fullName",
      width: 150,
    },
    {
      title: "Correo",
      dataIndex: "userName",
      key: "userName",
      width: 250,
    },
    {
      title: "Celular",
      dataIndex: "cellPhoneNumber",
      key: "cellPhoneNumber",
      width: 150,
    },
  ];

  const getTitle = () => {
    return (
      <div style={{padding: "10px"}}>
        <Row >
          <Col >
            <DownloadUserReport data={data} />
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <div>
       {getTitle()}
      <Table
        loading={isLoadingReport}
        columns={columns}
        dataSource={data}
        pagination={{ pageSize: 30 }}
        scroll={{ x: 500, y: 350 }}
        showSorterTooltip={false}
        locale={{
          filterConfirm: "Aceptar",
          filterReset: "Reiniciar",
          emptyText: "No se han encontrado datos",
        }}
      />
    </div>
  );
};

export default UserReport;
