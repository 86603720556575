import React, {useEffect, useState, useContext} from 'react'
import { useHistory } from 'react-router-dom'
import * as RestService from '../../services/RestService'
import { Col, Modal, Container, Button, Row, Image} from 'react-bootstrap';
import {  CloseCircleOutlined } from '@ant-design/icons';

import moment from "moment"
import Banner from './Banner'
import CustomTable from '../utilities/CustomTable'
import {Context} from '../../context/context'
import { Tag  } from 'antd';
import Settings from '../settings-component/Settings'

const BannerList = () => {

  const history = useHistory()
  const [bannerList, setBannerList] = useState([])
  const [loadingTable, setLoadingTable] = useState(true)
  const [context, ] = useContext(Context)
  const [showModal, setshowModal] = useState(false);


  useEffect(() => {

    const apiRequest = async () =>  {
      try{
        const bannersResponse = await RestService.axiosClientGet('/service/banner')
        let bannerList = bannersResponse.data
        bannerList.map(banner => banner.key = banner.id)
        setBannerList(bannerList)
        setLoadingTable(false)
      }catch(e){
        console.error("Error in BannerList ",e)
        history.push("/logout")
      }
    }

    apiRequest()

 }, [ history, context.reloadBannerList])

 const columns = [
  {
    title: 'Descripción',
    dataIndex: 'description',
    key: 'description',
    width: '30%',
    isSearchable: true
  },
  {
    title: 'Imagen',
    key: 'imagePath',
    width: '30%',
    render: (text, record) => (
      <Col md={10}>
        <Image src={record.imagePath} thumbnail />
      </Col>
    ),
  },
  {
    title: 'Fecha inicial',
    dataIndex: 'initialDate',
    key: 'initialDate',
    width: '10%',
    sorter: (a, b) => moment(a.initialDate, "DD/MM/YYYY").unix() - moment(b.initialDate, "DD/MM/YYYY").unix()
  },
  {
    title: 'Fecha Final',
    dataIndex: 'finalDate',
    key: 'finalDate',
    width: '10%',
    sorter: (a, b) => moment(a.finalDate, "DD/MM/YYYY").unix() - moment(b.finalDate, "DD/MM/YYYY").unix()
  },
  {
    title: 'Estado',
    key: 'isEnabled',
    dataIndex: 'isEnabled',
    filters: [
      {
        text: 'Habilitado',
        value: true,
      },
      {
        text: 'Deshabilitado',
        value: false,
      },
    ],
    onFilter: (value, record) => record.isEnabled === value,
    render: (text, record) => (
      <Tag color={record.isEnabled ? "green" : "volcano"}>
        {record.isEnabled ? "Habilitado" : "Deshabilitado"}
      </Tag>
      )
    },
    {
      title: 'Actualizar',
      key: 'action',
      actionType: 'update',
      align: 'center'
    },
  ];

  const getTitle = () => {
    return (
      <Row>
        <Col sm={10}>
          <h3 className="text-primary">Bannners publicitarios</h3>
        </Col>
        <Col sm={2}>
          <Button variant="link" className="float-right" type="submit" onClick={() => setshowModal(true)}>
            Actualizar Título
          </Button>
        </Col>

        <Modal size="lg" show={showModal} onHide={() => setshowModal(false)}>
          <Container style={{padding: "20px"}}>
          <CloseCircleOutlined  className="float-right" onClick={() => setshowModal(false)} style={{ fontSize: '25px', color: '#08c' }}/>
            <Settings closeModal={() => setshowModal(false)}/>
          </Container>
        </Modal>

      </Row>
    )
  }


  return(
    <CustomTable 
      loadingTable={loadingTable} 
      columns={columns} 
      dataSource={bannerList} 
      component={Banner}
      title={getTitle()}
      buttonText={"Crear Banner"}
    />
  )
}

export default BannerList