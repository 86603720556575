import React from 'react'
import { Layout, Menu } from 'antd';
import Banner from '../banner/Banner'
import BannerList from '../banner/BannerList'
import CategoryList from '../product/CategoryList'
import { Link } from "react-router-dom";
import PrivateRoute from '../security/PrivateComponent'
import { SoundOutlined, UserOutlined, NotificationOutlined, LineChartOutlined, UngroupOutlined , ShoppingCartOutlined, ShopOutlined, DeploymentUnitOutlined, SettingOutlined} from '@ant-design/icons';
import ProductList from '../product/ProductList';
import UsersList from '../users/UsersList'
import CouponList from '../coupons/CouponList'
import AddressCoverage from '../address-coverage/address-coverage'
import OfficeList from '../office/OfficeList'
import PushNotifications from '../push-notifications/PushNotifications'
import Setting from '../settings/Setting'
const {  Sider } = Layout;

const Administrate = () => {

  return(
    <Layout>
      <Layout>
        <Sider className="site-layout-background">
          <Menu mode="inline">
            <Menu.Item icon={<SoundOutlined />} key="1">
              <Link to="/administrate-app/banner-list">Banners Publicitarios</Link>
            </Menu.Item>
            <Menu.Item icon={<UngroupOutlined />} key="2">
              <Link to="/administrate-app/categories-products-list">Categorías Productos</Link>
            </Menu.Item>
            <Menu.Item icon={<ShoppingCartOutlined />} key="3">
              <Link to="/administrate-app/products-list">Productos</Link>
            </Menu.Item>
            <Menu.Item icon={<LineChartOutlined />} key="5">
              <Link to="/administrate-app/coupons-list">Cupones</Link>
            </Menu.Item>
            <Menu.Item icon={<ShopOutlined />} key="8">
              <Link to="/administrate-app/offices">Asaderos</Link>
            </Menu.Item>
            <Menu.Item icon={<UserOutlined />} key="14">
              <Link to="/administrate-app/users">Usuarios</Link>
            </Menu.Item>
            <Menu.Item icon={<DeploymentUnitOutlined />} key="15">
              <Link to="/administrate-app/address-coverage">Zona de no cobertura</Link>
            </Menu.Item>
            <Menu.Item icon={<NotificationOutlined />} key="16">
              <Link to="/administrate-app/push-notifications">Notificaciones Push</Link>
            </Menu.Item>
            <Menu.Item icon={<SettingOutlined />} key="17">
              <Link to="/administrate-app/settings">Configuraciones generales</Link>
            </Menu.Item>
          </Menu>
        </Sider>
        <div style={{padding: "15px" }}>
            <PrivateRoute exact path="/administrate-app/banner" component={Banner} roles={["ROLE_ADMIN"]}/>
            <PrivateRoute exact path="/administrate-app/banner-list" component={BannerList} roles={["ROLE_ADMIN"]}/>
            <PrivateRoute exact path="/administrate-app/categories-products-list" component={CategoryList} roles={["ROLE_ADMIN"]}/>
            <PrivateRoute exact path="/administrate-app/products-list" component={ProductList} roles={["ROLE_ADMIN"]}/>
            <PrivateRoute exact path="/administrate-app/coupons-list" component={CouponList} roles={["ROLE_ADMIN"]}/>
            <PrivateRoute exact path="/administrate-app/users" component={UsersList} roles={["ROLE_ADMIN"]}/>
            <PrivateRoute exact path="/administrate-app/offices" component={OfficeList} roles={["ROLE_ADMIN"]}/>
            <PrivateRoute exact path="/administrate-app/address-coverage" component={AddressCoverage} roles={["ROLE_ADMIN"]}/>
            <PrivateRoute exact path="/administrate-app/push-notifications" component={PushNotifications} roles={["ROLE_ADMIN"]}/>
            <PrivateRoute exact path="/administrate-app/settings" component={Setting} roles={["ROLE_ADMIN"]}/>
        </div>
      </Layout>
    </Layout>
  )
}

export default  Administrate


