import React, {useContext, useState, useEffect} from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import {Slider, Radio} from 'antd'
import swal from 'sweetalert';
import {Context} from '../../context/context'
import * as RestService from '../../services/RestService'

const ConfirmOrder = ({data, closeModal, setConfirmOrderIsLoading, confirmOrderIsLoading}) => {

  const [context, setContext] = useContext(Context);
  const [minutes, setMinutes] = useState(30);
  const [highestEstimatedTime, setHighestEstimatedTime] = useState(0);
  const [marks, setMarks] = useState({});

  /*useEffect(() => {
    let _highestEstimatedTime = 0;
    data.orderDetailList.map(orderDetail => {
      if (orderDetail.product.estimatedTime > _highestEstimatedTime){
        _highestEstimatedTime = orderDetail.product.estimatedTime;
      }
    })
    setHighestEstimatedTime(_highestEstimatedTime);
    setMarks({[_highestEstimatedTime]: getEstimatedTimeFromMinutes(_highestEstimatedTime), [_highestEstimatedTime + 20]: getEstimatedTimeFromMinutes(_highestEstimatedTime + 20)})
  }, [data.orderDetailList])

  const formatter = (minutes) => {
    return 'Tiempo estimado ' + getEstimatedTimeFromMinutes(minutes)
  }

  const getEstimatedTimeFromMinutes = (minutes) => {
    if (minutes >= 60){
      let hour = parseInt(minutes / 60);
      let minutesModule = minutes % 60;
      if (minutesModule === 0){
        return `${hour}h`
      }else{
        return `${hour}h ${minutesModule}min`
      }
    }else{
      return `${minutes}min`
    }
  }*/


  const confirmOrder = async(e) => {
    e.preventDefault()
    setConfirmOrderIsLoading(true)
    const categoriesResponse = await RestService.axiosClientPost(`/service/order/confirm_order/${data.id}?estimatedTime=${minutes}`, null, null, true);
    if(categoriesResponse){
      swal("Operación exitosa", `La orden fue confirmada`, "success",{button:  "Aceptar"});
    }else{
      swal("Ha ocurrido un error", `La orden no pudo ser confirmada`, "error",{button:  "Aceptar"});  
    }
    setConfirmOrderIsLoading(false)
    setContext({orderList: Math.random()})
    setContext({reloadOrderList: Math.random()})
    closeModal()
  }

  const onChangeMinute = (value) => {
    setMinutes(value.target.value);
  }

  return (
  <div>
			<h3 className="text-primary">Confirmar Pedido</h3>
      <hr/>
			<h5>Indique el tiempo estimado en el que el cliente obtendrá el pedido</h5>

      <Form>
        <Form.Group as={Row}>
					
          <Radio.Group onChange={onChangeMinute} style={{width: "100%"}} defaultValue={30}>
            <Radio  style={{width: "30%"}} value={30}>30 min</Radio>
            <Radio  style={{width: "30%"}} value={45}>45 min</Radio>
            <Radio  style={{width: "30%"}} value={60}>60 min</Radio>
          </Radio.Group>
				
				</Form.Group>

				<Form.Group>
					<Col sm={12}>
							<Button variant="primary" className="float-right" type="submit" onClick={confirmOrder} disabled={confirmOrderIsLoading}>
								Confirmar Orden
							</Button>
					</Col>
				</Form.Group>
			</Form>
    </div>
  )
}

export default ConfirmOrder;