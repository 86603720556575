import React from 'react'
import { Link, useLocation } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import PrivateHeader from '../security/PrivateHeader'

import * as AuthService from '../../services/AuthService'

const Header = (props) => {

  const location = useLocation()
  const inLogin = location.pathname === '/login'

  return(
     !AuthService.isAuth() || inLogin  ? (
      <div style={{display: "flex"}}> 
          <div style={{margin: "auto"}}>
          </div>
      </div>
    ) : (
      <div>
        <nav style={{position: "relative", height: '15%', background: 'linear-gradient(to left, #8ed32d, #36bf2d)'}} className="navbar navbar-expand-lg fixed-top" >
          <div className=" collapse navbar-collapse" >
            <PrivateHeader _to={"/order"} text={"Pedidos"} roles={["ROLE_OPERATOR"]}></PrivateHeader>
            <PrivateHeader _to={"/delivery"} text={"Domiciliarios"} roles={["ROLE_OPERATOR"]}></PrivateHeader>
            <PrivateHeader _to={"/chat"} text={"Chat"} roles={["ROLE_SUPPORT"]}></PrivateHeader>
            <PrivateHeader _to={"/administrate-app"} text={"Administar App"} roles={["ROLE_ADMIN"]}></PrivateHeader>
            <PrivateHeader _to={"/order-report"} text={"Reporte de Ordenes"} roles={["ROLE_ADMIN"]}></PrivateHeader>
            <PrivateHeader _to={"/user-report"} text={"Reporte de Usuarios"} roles={["ROLE_ADMIN"]}></PrivateHeader>

            {/*<Link className="navbar-brand" to={"/administrate-app"}>Administar App</Link>*/}
            <ul className="mr-auto"/>
            <Link className="navbar-brand" to={"/logout"}>
              <Button variant="light">Cerrar Sesión</Button> 
            </Link>
          </div>
        </nav>
      </div>
      )
  )
}

export default Header