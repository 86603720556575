import React, {useEffect, useState, useContext} from 'react'
import { useHistory } from 'react-router-dom'
import * as RestService from '../../services/RestService'
import { Col, Row} from 'react-bootstrap';

import CustomTable from '../utilities/CustomTable'
import {Context} from '../../context/context'

const AddressCoverage = () => {

  const history = useHistory()
  const [addressCoverageList, setAddressCoverageList] = useState([])
  const [loadingTable, setLoadingTable] = useState(true)
  const [context, ] = useContext(Context)
  const [showModal, setshowModal] = useState(false);


  useEffect(() => {

    const apiRequest = async () =>  {
      try{
        const addressCoverageResponse = await RestService.axiosClientGet('/service/address_coverage')
        let addressCoverageList = addressCoverageResponse.data
        addressCoverageList.map(addressCoverage => addressCoverage.key = addressCoverage.id)
        setAddressCoverageList(addressCoverageList)
        setLoadingTable(false)
      }catch(e){
        console.error("Error in addressCoverageList ",e)
        history.push("/logout")
      }
    }

    apiRequest()

 }, [ history, context.reloadBannerList])

 const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: '10%'
  },
  {
    title: 'Dirección',
    dataIndex: 'address',
    key: 'address',
    width: '30%',
  },
  {
    title: 'Latitud',
    dataIndex: 'latitude',
    key: 'latitude',
    width: '30%',
  },
  {
    title: 'Longitud',
    dataIndex: 'longitude',
    key: 'longitude',
    width: '30%',
  }
];

  const getTitle = () => {
    return (
      <Row>
        <Col sm={10}>
          <h3 className="text-primary">Zona de no cobertura</h3>
        </Col>
      </Row>
    )
  }


  return(
    <CustomTable 
      loadingTable={loadingTable} 
      columns={columns} 
      dataSource={addressCoverageList} 
      //component={Banner}
      title={getTitle()}
      //buttonText={"Crear Banner"}
    />
  )
}

export default AddressCoverage