import React from 'react'
import { Link } from 'react-router-dom'
import * as AuthService from '../../services/AuthService'

const PrivateHeader = (props) => {

  const isAuthorized = AuthService.hasRole(props.roles)

  return (
    AuthService.isAuth() && isAuthorized ? (
    <Link className="navbar-brand" to={props._to}>{props.text}</Link>
    ) : (
      null
    )
  )
}

export default PrivateHeader