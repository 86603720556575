import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import JavascriptTimeAgo from 'javascript-time-ago'
import es from 'javascript-time-ago/locale/es'

import App from './App';

import { registerServiceWorker } from './serviceWorker'

JavascriptTimeAgo.locale(es)

ReactDOM.render(
    <App />,
  document.getElementById('root')
);

registerServiceWorker();
