
import firebase from 'firebase';
import '@firebase/firestore'

//TEST
/*
if (!firebase.apps.length) {
  firebase.initializeApp({
    apiKey: "AIzaSyDNCkaGT87AxF_JvL4LwIJtRk7uBt9maKg",
    authDomain: "test-171c4.firebaseapp.com",
    databaseURL: "https://test-171c4.firebaseio.com",
    projectId: "test-171c4",
    storageBucket: "test-171c4.appspot.com",
    messagingSenderId: "915153685016",
    appId: "1:915153685016:web:4585c58c208a9eaeed116d",
    measurementId: "G-NK7GGWPMY0"
  });
}
*/
//Production

if (!firebase.apps.length) {
  firebase.initializeApp({
    apiKey: "AIzaSyAjzJjivDww2qRpF6cHhyql5-2wh8PcJBg",
    authDomain: "brosty-faf27.firebaseapp.com",
    databaseURL: "https://brosty-faf27-default-rtdb.firebaseio.com",
    projectId: "brosty-faf27",
    storageBucket: "brosty-faf27.appspot.com",
    messagingSenderId: "396582776740",
    appId: "1:396582776740:web:8fd1ce429f8a2145a49b31"
  });
}


export const fire = firebase;
