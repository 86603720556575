import React, {useEffect, useState, useContext} from 'react'
import { useHistory } from 'react-router-dom'
import * as RestService from '../../services/RestService'
import { Modal, Container} from 'react-bootstrap';
import { FileDoneOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { MdRemoveRedEye } from "react-icons/md";
import { FaMotorcycle } from "react-icons/fa";
import { FcApproval } from "react-icons/fc";
import { GiChickenOven } from "react-icons/gi";
import { BiUserCheck } from "react-icons/bi";
import OrderState from './OrderState'
import SelectDeliveryMan from './SelectDeliveryMan'
import ConfirmOrder from './ConfirmOrder'
import PrintOrder from './PrintOrder'

import cookie from 'js-cookie';

import ProductListResume from './ProductListResume'
//import moment from 'moment/min/moment-with-locales'
import CustomTable from '../utilities/CustomTable'
import {Context} from '../../context/context'
import { Tag  } from 'antd';
import swal from 'sweetalert';
import { RiLoader2Fill } from "react-icons/ri";
//moment.locale('es');

const OrderList = () => {

  const history = useHistory()
  const [modalData, setModalData] = useState({})
  const [orderList, setOrderList] = useState([])
  const [loadingTable, setLoadingTable] = useState(true)
  const [context, setContext] = useContext(Context)
	const [showModalEditState, setShowModalEditState] = useState(false);
	const [showModalDeliveryMan, setShowModalDeliveryMan] = useState(false);
  const [showModalDetails, setShowModalDetails] = useState(false);
  const [showModalConfirmOrder, setShowModalConfirmOrder] = useState(false);
  const [confirmOrderIsLoading, setConfirmOrderIsLoading] = useState(false);

  const audio = new Audio("https://notificationsounds.com/storage/sounds/file-sounds-897-alarm-frenzy.mp3")
	
  useEffect(() => {

    const apiRequest = async () =>  {
      try{
        const office = JSON.parse(cookie.get("office"));
        const orderResponse = await RestService.axiosClientGet(`/service/order/office/${office.id}`)
        let orderList = orderResponse.data
        console.log(orderList)
        orderList.map(order => {
					order.key = order.id
        })
        setOrderList(orderList)
        setLoadingTable(false)
      }catch(e){
        console.error("error in OrderList", e)
        history.push("/logout")
      }
    }

		apiRequest()
		
		const interval = setInterval(() => {
      apiRequest()
    }, 60000);
    return () => clearInterval(interval);

 }, [ history, context.reloadOrderList])

 useEffect(() => {  
    const interval = setInterval(() => {
      if(orderList.some(order => order.state === "REQUESTED" || order.state === "REQUESTED_STORE_PICKUP")){
        audio.play();
      }
    }, Number(cookie.get("miliseconds_sound_orders")));

    return () => clearInterval(interval); 
  }, [orderList])

 const handleShowModalEditState = (dataUpdate) => {
  setModalData(dataUpdate);
  setShowModalEditState(true);
}
const handleCloseModalEditState = () => setShowModalEditState(false);


const handleShowModalDeliveryMan = (dataUpdate) => {
	if(dataUpdate.state === "REQUESTED"){
		swal("Información", `La orden no ha sido confirmadas`, "info",{button:  "Aceptar"});
		return;
  }
  if(dataUpdate.state !== "CONFIRMED"){
		swal("Información", `La orden ya se ha asociado a un domiciliario`, "info",{button:  "Aceptar"});
		return;
	}
  setModalData(dataUpdate);
  setShowModalDeliveryMan(true);
}
const handleCloseModalDeliveryMan = () => setShowModalDeliveryMan(false);

const handleShowModalDetails = (dataUpdate) => {
  setModalData(dataUpdate);
  setShowModalDetails(true);
}
const handleCloseModalDetails = () => setShowModalDetails(false);

const handleShowModalConfirmOrder = (dataUpdate) => {
  if(dataUpdate.state !== 'REQUESTED' && dataUpdate.state !== 'REQUESTED_STORE_PICKUP'){
    swal("Información", `La orden ya ha sido confirmada`, "info",{button:  "Aceptar"});
    return;
  }
  setModalData(dataUpdate);
  setShowModalConfirmOrder(true);
}
const handleCloseModalConfirmOrder = () => setShowModalConfirmOrder(false);

const handlePrepareOrder = async(dataUpdate) => {

	if(dataUpdate.state === "REQUESTED_STORE_PICKUP"){
		swal("Información", `La orden no ha sido confirmada`, "info",{button:  "Aceptar"});
		return;
  }
  if(dataUpdate.state !== "CONFIRMED_STORE_PICKUP"){
		swal("Información", `La orden ya ha sido actualizada como preparada`, "info",{button:  "Aceptar"});
		return;
	}
  
  const response = await swal({
    title: "Actualizar pedido como preparado",
    text: "Una vez actualizado el usuario podrá ver que su pedido esta listo",
    icon: "info",
    buttons:  ["Cancelar", "Aceptar"]
  })
  if (response) {
    const preparedResponse = await RestService.axiosClientPost(`/service/order/prepare_order/${dataUpdate.id}`, null, null, true);
    if(preparedResponse){
      swal("Operación exitosa", `La orden fue actualizada como preparada`, "success",{button:  "Aceptar"});
    }else{
      swal("Ha ocurrido un error", `La orden no pudo ser actualizada`, "error",{button:  "Aceptar"});  
    }
    setContext({reloadOrderList: Math.random()})
  }
}

const handleDeliveryStorePickupOrder = async(dataUpdate) => {

	if(dataUpdate.state === "REQUESTED_STORE_PICKUP"){
		swal("Información", `La orden no ha sido confirmada`, "info",{button:  "Aceptar"});
		return;
  }
  if(dataUpdate.state === "CONFIRMED_STORE_PICKUP"){
		swal("Información", `La orden no se ha actualizado como preparada`, "info",{button:  "Aceptar"});
		return;
	}
  if(dataUpdate.state !== "PREPARED_STORE_PICKUP"){
		swal("Información", `La orden ya fue actualizada como entregada al cliente`, "info",{button:  "Aceptar"});
		return;
	}
  
  const response = await swal({
    title: "Entregar pedido",
    text: "Actualizar pedido como entregado al cliente",
    icon: "info",
    buttons:  ["Cancelar", "Aceptar"]
  })
  if (response) {
    const preparedResponse = await RestService.axiosClientPost(`/service/order/finish_order/${dataUpdate.id}`, null, null, true);
    if(preparedResponse){
      swal("Operación exitosa", `La orden fue finalizada y entregada al cliente`, "success",{button:  "Aceptar"});
    }else{
      swal("Ha ocurrido un error", `La orden no pudo ser finalizada`, "error",{button:  "Aceptar"});  
    }
    setContext({reloadOrderList: Math.random()})
  }
}

 const columns = [
  {
    title: 'Cliente',
    dataIndex: 'user',
    key: 'user',
    width: '18%',
    render: (text, record) => (
      <p>{`${text.name} ${text.lastName}`}</p>
      ),
  },
  {
    title: 'Celular',
    dataIndex: 'user',
    key: 'user',
    width: '12%',
    render: (text, record) => (
      <p>{`${text.cellPhoneNumber}`}</p>
      ),
	},
	{
    title: 'Domiciliario',
    dataIndex: 'deliveryMan',
    key: 'deliveryMan',
    width: '18%',
    render: (text, record) => (
      text ? <p>{`${text.name} ${text.lastName}`}</p> : 'NO ASIGNADO'
    ),
  },
  {
    title: 'Valor Total',
    dataIndex: 'totalPrice',
    key: 'totalPrice',
    width: '9%'
  },
  {
    title: 'Fecha de pedido',
    dataIndex: 'date',
    key: 'date',
    width: '16%',
		//sorter: (a, b) => moment(a.date, "YYYY-MM-DD hh:mm:ss") - moment(b.date, "YYYY-MM-DD hh:mm:ss").unix(),
  },
  {
    title: 'Estado',
    width: '17%',
    key: 'state',
    dataIndex: 'state',
    filters: [
      {
        text: 'Solicitado',
        value: 'REQUESTED',
      },
      {
        text: 'Solicitado - Entrega en P.V',
        value: 'REQUESTED_STORE_PICKUP',
      },
      {
        text: 'Confirmado',
        value: 'CONFIRMED',
      },
      {
        text: 'Confirmado - Entrega en P.V',
        value: 'CONFIRMED_STORE_PICKUP',
      },
      {
        text: 'En entrega',
        value: 'IN_DELIVERY',
      },
      {
        text: 'Preparado - Entrega en P.V',
        value: 'PREPARED_STORE_PICKUP',
      },
      {
        text: 'Entregado',
        value: 'DELIVERED',
      },
      ,
      {
        text: 'Entregado en P.V',
        value: 'DELIVERED_STORE_PICKUP',
      }
    ],
    onFilter: (value, record) => record.state === value,
    render: (text, record) => (
    <div>
      <Tag color={record.state === 'REQUESTED' ? "magenta" : record.state === 'CONFIRMED' ? "gold" : record.state === 'IN_DELIVERY' ? "geekblue" : record.state === 'REQUESTED_STORE_PICKUP' ? "magenta" : record.state === 'CONFIRMED_STORE_PICKUP' ? "gold" : record.state === 'PREPARED_STORE_PICKUP' ? "geekblue":"green"}>
        {record.state === 'REQUESTED' ? "Solicitado" : record.state === 'CONFIRMED' ? "Confirmado" : record.state === 'IN_DELIVERY' ? "En entrega" : record.state === 'REQUESTED_STORE_PICKUP' ? "Solicitado - Entrega en P.V" : record.state === 'CONFIRMED_STORE_PICKUP' ? "Confirmado - Entrega en P.V" : record.state === 'PREPARED_STORE_PICKUP' ? "Preparado - Entrega en P.V" : record.state === 'DELIVERED_STORE_PICKUP' ? "Entregado en P.V" : "Entregado"}      
      </Tag>
    </div>
    )},
    {
      title: 'Acciones',
      width: '20%',
      align: 'center',
      render: (text, record) => (
      <div>
          <div style={{ cursor:"pointer", fontSize: '20px',marginRight: '10%', display: 'inline' }} title="Confirmar pedido">
            {confirmOrderIsLoading ? 
            <RiLoader2Fill/>:
            <FcApproval style={{  color: '#08c'}} onClick={() => handleShowModalConfirmOrder(record)}/>
          }
          </div>
          { !record.storePickup &&
          <div style={{ cursor:"pointer", fontSize: '20px',marginRight: '10%', display: 'inline' }} title="Asignar Domiciliario">
            <FaMotorcycle style={{ color: '#0f4c75' }} onClick={() => handleShowModalDeliveryMan(record)}/>
          </div>
          }
          { record.storePickup &&
          <div style={{ cursor:"pointer", fontSize: '20px',marginRight: '10%', display: 'inline' }} title="Actualizar como preparado">
            <GiChickenOven style={{ color: '#0f4c75' }} onClick={() => handlePrepareOrder(record)}/>
          </div>
          }
          { record.storePickup &&
            <div style={{ cursor:"pointer", fontSize: '20px',marginRight: '10%', display: 'inline' }} title="Entregado al usuario">
              <BiUserCheck style={{ color: '#0f4c75' }} onClick={() => handleDeliveryStorePickupOrder(record)}/>
            </div>
          }
          <div style={{ cursor:"pointer", fontSize: '20px',marginRight: '10%', display: 'inline' }} title="Ver estados del pedido">
            <MdRemoveRedEye style={{ color: '#1b262c' }} onClick={() => handleShowModalEditState(record)}/>
          </div>
          <div style={{ cursor:"pointer", fontSize: '20px',marginRight: '10%', display: 'inline' }} title="Ver detalle">
            <FileDoneOutlined style={{ color: '#3282b8' }} onClick={() => handleShowModalDetails(record)}/>
          </div>
          <div style={{ cursor:"pointer", fontSize: '20px',marginRight: '10%', display: 'inline' }} title="Imprimir">
            <PrintOrder order={record}/>
          </div>
          
      </div>
      )},
  ];

  const getTitle = () => {
    const office = JSON.parse(cookie.get("office"));
    return (
      
      <>
      
          <h3 className="text-primary" style={{float: "left"}}>Ordenes</h3>
          <h3 className="text-primary" style={{marginLeft: "20%"}}>{`Asadero: ${office.name}`}</h3>
     

      {/*
        <Modal size="lg" show={showModal} onHide={() => setshowModal(false)}>
          <Container style={{padding: "20px"}}>
          <CloseCircleOutlined  className="float-right" onClick={() => setshowModal(false)} style={{ fontSize: '25px', color: '#08c' }}/>
            <Settings closeModal={() => setshowModal(false)}/>
          </Container>
        </Modal>*/
  }

      </>
    )
  }


  return(
    <>
      <CustomTable 
        loadingTable={loadingTable} 
        columns={columns} 
        dataSource={orderList} 
        component={null}
        titleOrderList={getTitle()}
        buttonText={""}
      />
      <Modal show={showModalEditState} onHide={handleCloseModalEditState}>
          <Container style={{padding: "20px"}}>
          <CloseCircleOutlined  className="float-right" onClick={handleCloseModalEditState} style={{ fontSize: '25px', color: '#08c' }}/>
            <OrderState data={modalData} closeModal={handleCloseModalEditState}/>
          </Container>
      </Modal>

			<Modal show={showModalDeliveryMan} onHide={handleCloseModalDeliveryMan}>
          <Container style={{padding: "20px"}}>
          <CloseCircleOutlined  className="float-right" onClick={handleCloseModalDeliveryMan} style={{ fontSize: '25px', color: '#08c' }}/>
            <SelectDeliveryMan data={modalData} closeModal={handleCloseModalDeliveryMan}/>
          </Container>
      </Modal>

      <Modal show={showModalConfirmOrder} onHide={handleCloseModalConfirmOrder}>
          <Container style={{padding: "20px"}}>
          <CloseCircleOutlined  className="float-right" onClick={handleCloseModalConfirmOrder} style={{ fontSize: '25px', color: '#08c' }}/>
            <ConfirmOrder data={modalData} closeModal={handleCloseModalConfirmOrder} setConfirmOrderIsLoading={setConfirmOrderIsLoading} confirmOrderIsLoading={confirmOrderIsLoading} />
          </Container>
      </Modal>

      <Modal  size="lg" show={showModalDetails} onHide={handleCloseModalDetails}>
          <Container style={{padding: "20px"}}>
          <CloseCircleOutlined  className="float-right" onClick={handleCloseModalDetails} style={{ fontSize: '25px', color: '#08c' }}/>
            <ProductListResume order={modalData} closeModal={handleCloseModalDetails}/>
          </Container>
      </Modal>
    </>
  )
}

export default OrderList