
import React, {useState, useEffect, useRef, useContext} from 'react'
import { Form, Button, Row, Col, OverlayTrigger, Popover } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import locale_es from "date-fns/locale/es"; 
import { useForm } from "react-hook-form";
import moment from "moment"
import { InfoCircleTwoTone } from '@ant-design/icons';
import { Spin, Checkbox, Tag } from 'antd';
import AlertForm from '../utilities/AlertForm'
import * as RestService from '../../services/RestService'
import {Context} from '../../context/context'
import {imageSizeExceedLimit} from '../../services/FileService';

import "./banner.css"

const Banner = (props) => {

  const { handleSubmit, register, errors, setValue } = useForm();
  const [isEnabled, setIsEnabled] = useState(true);
  const [initialDate, setInitialDate] = useState(new Date());
  const [finalDate, setFinalDate] = useState(null);
  const [file, setFile] = useState(null);
  const [fileLabel, setFileLabel] = useState("Cargar imagen del banner de publicidad");
  const [isLoading, setIsLoading] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [isUpdate, setIsUpdate] = useState(false);
  const [context, setContext] = useContext(Context)
  const [fileSizeExceedLimit, setFileSizeExceedLimit] = useState(false);

  const buttonUploadFile = useRef();


  useEffect(() => {

    if(props.data){
      setValue("description", props.data.description)
      setInitialDate(moment(props.data.initialDate, "DD/MM/YYYY").toDate())
      setFinalDate(moment(props.data.finalDate, "DD/MM/YYYY").toDate())
      setFileLabel(props.data.imagePath.split("-")[1])
      setFile(props.data.imagePath)
      setIsEnabled(props.data.isEnabled)
      setIsUpdate(true)
    }

  }, [props.data, setValue])


  const onSubmmit = async data => {
    
    setFirstLoad(false)
    if(!initialDate || !finalDate || !file || imageSizeExceedLimit(file, 60, setFileSizeExceedLimit))
      return;

    var formData = new FormData();

    formData.append('description', data["description"]);
    formData.append('initialDate', moment(initialDate).format("DD/MM/YYYY"));
    formData.append('finalDate',  moment(finalDate).format("DD/MM/YYYY"));
    formData.append('isEnabled',  isEnabled);
    formData.append('file', file);

    setIsLoading(true)
    if(isUpdate){
      formData.append('id', props.data.id);
      await RestService.axiosFormdataPut('/service/banner', formData, 'banner')
    }else{
      formData.append('id', 1);
      await RestService.axiosFormdataPost('/service/banner', formData, 'banner')
    }
    setIsLoading(false)
    clearFormFields()
    props.closeModal()
    setContext({reloadBannerList: !context.reloadBannerList})

  }


  const handlerFile = (e) => {

    if(buttonUploadFile.current && buttonUploadFile.current.files && buttonUploadFile.current.files[0] ){
      setFileLabel(buttonUploadFile.current.files[0].name)
      setFile(buttonUploadFile.current.files[0])
    }else{
      setFileLabel("Cargar imagen del banner de publicidad");
      setFile(null)
    }
  }

  const clearFormFields = () => {
    setInitialDate(new Date())
    setFinalDate(null)
    setFile(null)
    setFileLabel("Cargar imagen del banner de publicidad");
    setFirstLoad(true)
  }

  const popover = (
    <Popover>
      <Popover.Title as="h3">Fecha Final</Popover.Title>
      <Popover.Content>
        La publicidad será mostrada hasta las 23:59:59 de la fecha final seleccionada.
      </Popover.Content>
    </Popover>
  );

  return(
      <Form onSubmit={handleSubmit(onSubmmit)}>

      <Form.Group>
        <h3 className="text-primary">{isUpdate ? 'Actualizar': 'Registrar'} Banner de publicidad</h3>
        <hr/>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={2}>Descripción</Form.Label>
        <Col sm={10}>
          <Form.Control 
            ref={register({
              required: "Ingrese una descripción del banner",
            })}
            as="textarea" 
            placeholder="Descripción" 
            name="description"/>
        </Col>
      </Form.Group>

      {errors.description && <AlertForm message={errors.description.message}/>}
      
      <Form.Group as={Row}>
        <Form.Label column sm={2}>Habilitar</Form.Label>
        <Col sm={10}>
          <Checkbox checked={isEnabled} onChange={() => setIsEnabled(!isEnabled)}>
            <Tag color={isEnabled ? "green" : "volcano"}>
              {isEnabled ? "Habilitado" : "Deshabilitado"}
            </Tag>
          </Checkbox>
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={1} lg={2}>Fecha Inicial</Form.Label>
        <Col sm={7}>
          <DatePicker
            onChange={val => setInitialDate(val)}
            locale={locale_es}
            name="initialDate"
            disabledKeyboardNavigation
            dateFormat="d/MM/yyyy"
            className="form-control" 
            placeholderText="Seleccione la fecha inicial"
            autoComplete="off"
            selected={initialDate}
          />
        </Col>
      </Form.Group>

      {!initialDate && !firstLoad && <AlertForm message={"La fecha inicial es obligatoria"}/>}


      <Form.Group as={Row}>
        <Form.Label column sm={1} lg={2}>Fecha Final</Form.Label>
      
        <Col sm={7}>
          <DatePicker
            onChange={val => setFinalDate(val)}
            locale={locale_es}
            name="finalDate"
            disabledKeyboardNavigation
            dateFormat="d/MM/yyyy"
            className="form-control" 
            placeholderText="Seleccione la fecha final"
            autoComplete="off"
            selected={finalDate}
          />
          <OverlayTrigger trigger="click" placement="right" overlay={popover}>
            <InfoCircleTwoTone className={"p-3"}/>
          </OverlayTrigger>
          
        </Col>
      </Form.Group>

      {!finalDate && !firstLoad && <AlertForm message={"La fecha final es obligatoria"}/>}

      <Form.Group as={Row}>
        <Form.Label column sm={2}>Cargar imagen del banner de publicidad</Form.Label>
          <Col sm={10}>
            <Form.File 
              id="custom-file-translate-html"
              label={fileLabel}
              data-browse="Cargar imagen"
              custom
              ref={buttonUploadFile}
              name="file"
              onChange={handlerFile}
            />
          </Col>
      </Form.Group>

      {!file && !firstLoad &&  <AlertForm message={"La imagen es obligatoria"}/>}
      {fileSizeExceedLimit && !firstLoad &&  <AlertForm message={"El tamaño máximo de la imagen debe ser 60 KB"}/>}
      
      <Form.Group>
        <Col sm={12}>
          {isLoading ? 
            <Spin className="float-right" size="large" /> 
          : 
            <Button variant="primary" className="float-right" type="submit">
              {isUpdate ? 'Actualizar': 'Registrar'} Banner
            </Button>
          }
        </Col>
      </Form.Group>
    </Form>
  )  
}

export default Banner