import React from 'react'
import { Collapse, Descriptions } from 'antd';
import { Form, Row } from 'react-bootstrap';

const { Panel } = Collapse;
const UserDetail = () => {

  return (
    <>
    <h1>Reporte de usuario</h1>

    <h5>Nombre completo: </h5>
    <h5>telefono: </h5>
    <h5>Barrio: </h5>
    <h5>Dirección: </h5>
    <h5>Correo: </h5>
    <h5>Ordenes realizadas: </h5>
    <Collapse >
      <Panel header={"orden 1"}>
          <Form.Group as={Row}>
            <Form.Label  className="text-primary" style={{textAlign: 'center'}} column sm={12}>Orden 1</Form.Label>
          </Form.Group>

        <div >
            <Descriptions>
              <Descriptions.Item label="Información completa de la orden 1"></Descriptions.Item>
            </Descriptions>
        </div>
      </Panel>
    </Collapse>

    <Collapse >
      <Panel header={"orden 2"}>
          <Form.Group as={Row}>
            <Form.Label  className="text-primary" style={{textAlign: 'center'}} column sm={12}>Orden 2</Form.Label>
          </Form.Group>

        <div >
            <Descriptions>
              <Descriptions.Item label="Información completa de la orden 2"></Descriptions.Item>
            </Descriptions>
        </div>
      </Panel>
    </Collapse>

    <h5>Actualizar notas: </h5>
    <textarea name="" id="" cols="30" rows="10"></textarea><br></br>
    <button>Actualizar notas</button>
    <h5>Envio de notificaciones: </h5>
    <textarea name="" id="" cols="30" rows="10"></textarea><br></br>
    <button>Enviar push notification</button>
    </>
  )
}

export default UserDetail;