import React, {useState, useEffect, useRef, useContext} from 'react'
import { useHistory } from 'react-router-dom'
import { Form, Button, Row, Col, Modal, Container } from 'react-bootstrap';
import { CloseCircleOutlined } from '@ant-design/icons';
import { useForm } from "react-hook-form";
import { Spin, Checkbox, Tag, Select as SelectAntd} from 'antd';
import AlertForm from '../utilities/AlertForm'
import * as RestService from '../../services/RestService'
import {Context} from '../../context/context'
import Select from 'react-select';
import './Product.css'
import ContentList from '../content/ContentList'
import {imageSizeExceedLimit} from '../../services/FileService';

const Product = (props) => {

  const { Option } = SelectAntd;


  const textFileInput = "Cargar imagen del producto";

  const history = useHistory();
  const { handleSubmit, register, errors, setValue } = useForm();

  const [additionsCategoriesSelected, setAdditionsCategoriesSelected] = useState([]);
  const [additionsCategoriesList, setAdditionsCategoriesList] = useState([]);

  const [contentList, setContentList] = useState([]);

  const [showModal, setShowModal] = useState(false);

  const [categoriesList, setCategoriesList] = useState([]);
  const [categoryObject, setCategoryObject] = useState(null);

  const [isEnabled, setIsEnabled] = useState(true);

  const [file, setFile] = useState(null);
  const [fileSizeExceedLimit, setFileSizeExceedLimit] = useState(false);
  const [fileLabel, setFileLabel] = useState(textFileInput);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCategories, setIsLoadingCategories] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);

  const [isUpdate, setIsUpdate] = useState(false);
  const [context, setContext] = useContext(Context)

  const buttonUploadFile = useRef();

  useEffect(() => {
    
    getCategoriesList()

    if(props.data){
      setValue("name", props.data.name)
      setValue("price", props.data.price)
      setValue("estimatedTime", props.data.estimatedTime)
      setFileLabel(props.data.imagePath.split("-")[1])
      setFile(props.data.imagePath)
      setIsEnabled(props.data.isEnabled)
      setIsUpdate(true)
      setCategoryObject({label: props.data.productCategory.name, value: props.data.productCategory.id})
      setAdditionsCategoriesSelected(props.data.additionsCategories.map(id => String(id)))
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data, setValue])

  const getCategoriesList = async () =>  {
    setIsLoadingCategories(true)
    try{
      const categoriesResponse = await RestService.axiosClientGet('/service/product_category')
      let categoriesList = categoriesResponse.data

      categoriesList.map(category => {
        category.value = category.id;
        category.label = category.name;
        return category;
      })
      setCategoriesList(categoriesList)

      let childrenCategories = [];
      categoriesList.map( category => ( childrenCategories.push(<Option key={category.id}>{category.name}</Option> )))
      setAdditionsCategoriesList(childrenCategories)

      await loadContentList()


    }catch(e){
      console.error("Error in product-getCategoriesList", e)
      history.push("/logout")
    }
    setIsLoadingCategories(false)
  }

  const loadContentList = async () => {
    var contentList = []
    var childrenContent = [];
    if(props.data){
      const contentResponse = await RestService.axiosClientGet(`/service/content/${props.data.id}`)
      contentList = contentResponse.data
      childrenContent = [];
      contentList.map( content => ( childrenContent.push(<Tag key={content.id} style={{marginTop: '2%'}} color="blue">{`${content.name} (${content.quantity})`}</Tag>)))
      setContentList(childrenContent)
      setContext({reloadContentList: !context.reloadContentList})
    }else{
      if(context.contentList){
        contentList = (JSON.parse(JSON.stringify(context.contentList)))
      } 
      else {
        setContext({contentList: []})
        contentList = []
      }
      childrenContent = [];
      contentList.map( content => ( childrenContent.push(<Tag key={content.key} style={{marginTop: '2%'}} color="blue">{`${content.name} (${content.quantity})`}</Tag>)))
      setContentList(childrenContent)
    }
  }

  const handleShowModal = () => {
    setShowModal(true);
  }
  const handleCloseModal = async() => {
    await loadContentList()
    setShowModal(false);
  }

  const onSubmmit = async data => {
    
    setFirstLoad(false)

    if(!file || !categoryObject || contentList.length < 1 || imageSizeExceedLimit(file, 60, setFileSizeExceedLimit))
      return;
      

    var formData = new FormData();

    formData.append('name', data["name"]);
    formData.append('isEnabled',  isEnabled);
    formData.append('file', file);
    formData.append('productCategory', categoryObject.value);
    formData.append('additionsCategories', additionsCategoriesSelected);
    formData.append('price', data["price"]);
    formData.append('estimatedTime', data["estimatedTime"]);

    setIsLoading(true)
    if(isUpdate){
      formData.append('id', props.data.id);
      await RestService.axiosFormdataPut('/service/product', formData, 'producto')
    }else{
      context.contentList.map((content, i) =>  {
        formData.append(`contentList[${i}].name`, content.name);
        formData.append(`contentList[${i}].quantity`, content.quantity);
      })
      await RestService.axiosFormdataPost('/service/product_content', formData, 'producto')
    }
    setIsLoading(false)
    clearFormFields()
    props.closeModal()
    setContext({reloadProductList: !context.reloadProductList})

  }

  const handlerFile = (e) => {

    if(buttonUploadFile.current && buttonUploadFile.current.files && buttonUploadFile.current.files[0] ){
      setFileLabel(buttonUploadFile.current.files[0].name)
      setFile(buttonUploadFile.current.files[0])
    }else{
      setFileLabel(textFileInput);
      setFile(null)
    }
  }

  const clearFormFields = () => {
    setFile(null)
    setFileLabel(textFileInput);
    setFirstLoad(true)
  }

  const handleChangeAdditionsCategoriesSelect = (value) => {
    setAdditionsCategoriesSelected(value)
  }

  /*
  const imageSizeExceedLimit = (file) => {
    if((file.size /1000) > 60){
      setFileSizeExceedLimit(true)
      return true;
    }else{
      setFileSizeExceedLimit(false)
      return false;
    }
  }*/


  return(
      <Form onSubmit={handleSubmit(onSubmmit)}>

      <Form.Group>
        <h3 className="text-primary">{isUpdate ? 'Actualizar': 'Registrar'} Producto</h3>
        <hr/>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={3}>Nombre</Form.Label>
        <Col sm={9}>
          <Form.Control 
            ref={register({
              required: "Ingrese un nombre para el producto",
              maxLength: 18
            })}
            as="input" 
            placeholder="Nombre" 
            name="name"/>
        </Col>
      </Form.Group>

      {errors.name && errors.name.type === "required" && <AlertForm message={errors.name.message}/>}
      {errors.name && errors.name.type === "maxLength" && <AlertForm message={"El número máximo de caracteres es de 18"}/>}

      <Form.Group as={Row}>
        <Form.Label column sm={3}>Precio</Form.Label>
        <Col sm={9}>
          <Form.Control 
            ref={register({
              required: "Ingrese el precio del producto",
              pattern: {
                value: /^[0-9]*$/i,
                message: "El precio debe ser expresada en números"
              }
            })}
            as="input" 
            placeholder="Precio" 
            name="price"/>
        </Col>
      </Form.Group>

      {errors.price && <AlertForm message={errors.price.message}/>}

      <Form.Group as={Row}>
        <Form.Label column sm={3}>Tiempo Estimado en minutos</Form.Label>
        <Col sm={9}>
          <Form.Control 
            ref={register({
              required: "Ingrese el tiempo estimado en minutos",
              pattern: {
                value: /^[0-9]*$/i,
                message: "El tiempo debe ser expresado en números"
              }
            })}
            as="input" 
            placeholder="Tiempo estimado en minutos" 
            name="estimatedTime"/>
        </Col>
      </Form.Group>

      {errors.estimatedTime && <AlertForm message={errors.estimatedTime.message}/>}

      <Form.Group as={Row}>
        <Form.Label column sm={3}>Habilitar</Form.Label>
        <Col sm={9}>
          <Checkbox checked={isEnabled} onChange={() => setIsEnabled(!isEnabled)}>
            <Tag color={isEnabled ? "green" : "volcano"}>
              {isEnabled ? "Habilitado" : "Deshabilitado"}
            </Tag>
          </Checkbox>
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={3}>Selecciona la Categoría</Form.Label>
        <Col className={"z"}  sm={9}>
          {isLoadingCategories ? 
            <Spin className="float-right" size="large" /> 
          :( 
            <Select 
              placeholder={"Seleccionar Categoría"}
              value={categoryObject}
              style={{ zIndex:"99999 !important"}} 
              options={categoriesList}  
              onChange={opt =>  opt.value ? setCategoryObject({label: opt.label, value: opt.value}) : setCategoryObject(null)}
            /> 
          )}
        </Col>
      </Form.Group>

      {!categoryObject && !firstLoad &&  <AlertForm message={"Debe Seleccionar una categoría"}/>}

      <Form.Group as={Row}>
        <Form.Label column sm={3}>Seleccione las categorías de las adiciones</Form.Label>
        <Col sm={9}>
            {isLoadingCategories ? (<Spin className="float-right" size="large" /> ) : 
            (
            <SelectAntd
              mode="multiple"
              style={{ width: '100%' }}
              placeholder="Seleccionar Categorías de las adiciones"
              value={additionsCategoriesSelected}
              onChange={handleChangeAdditionsCategoriesSelect}
              allowClear
            >
              { additionsCategoriesList }
          </SelectAntd>
            )}
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={3}>Contenido del producto</Form.Label>
        <Col sm={6}>
            {isLoadingCategories ? (<Spin className="float-right" size="large" /> ) : 
            (
              <div>
                  {contentList}
              </div>
            )}
        </Col>
        <Col sm={3}>
          <Button variant="info" className="float-right" disabled={isLoadingCategories} onClick={handleShowModal}>
            Administrar
          </Button>
        </Col>
      </Form.Group>

      {contentList.length < 1 && !firstLoad &&  <AlertForm message={"El producto debe tener almenos un contenido"}/>}


      <Form.Group as={Row}>
        <Form.Label column sm={3}>Cargar imagen del producto</Form.Label>
          <Col sm={9}>
            <Form.File 
              id="custom-file-translate-html"
              label={fileLabel}
              data-browse="Cargar imagen"
              custom
              ref={buttonUploadFile}
              name="file"
              onChange={handlerFile}
            />
          </Col>
      </Form.Group>

      {!file && !firstLoad &&  <AlertForm message={"La imagen es obligatoria"}/>}
      {fileSizeExceedLimit && !firstLoad &&  <AlertForm message={"El tamaño máximo de la imagen debe ser 60 KB"}/>}

      <Form.Group>
        <Col sm={12}>
          {isLoading ? 
            <Spin className="float-right" size="large" /> 
          : 
            <Button variant="primary" className="float-right" type="submit" disabled={isLoadingCategories}>
              {isUpdate ? 'Actualizar': 'Registrar'} Producto
            </Button>
          }
        </Col>
      </Form.Group>

      <Modal size="lg" show={showModal} onHide={handleCloseModal}>
        <Container style={{padding: "20px"}}>
        <CloseCircleOutlined  className="float-right" onClick={handleCloseModal} style={{ fontSize: '25px', color: '#08c' }}/>
          <ContentList productId={props.data ? props.data.id : null}/>
        </Container>
      </Modal>

    </Form>
  )  
}

export default Product