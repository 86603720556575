import React, {useEffect, useState, useContext} from 'react'
import { useHistory } from 'react-router-dom'
import * as RestService from '../../services/RestService'
import Category from './Category'
import CustomTable from '../utilities/CustomTable'
import {Context} from '../../context/context'
import { Tag } from 'antd';

const CategoryList = () => {

  const history = useHistory()
  const [categoryList, setCategoryList] = useState([])
  const [loadingTable, setLoadingTable] = useState(true)
  const [context, ] = useContext(Context)

  useEffect(() => {

    const apiRequest = async () =>  {
      try{
        const categoriesResponse = await RestService.axiosClientGet('/service/product_category')
        let categoriesList = categoriesResponse.data
        categoriesList.map(category => category.key = category.id)
        setCategoryList(categoriesList)
        setLoadingTable(false)
      }catch(e){
        console.error("error in CategoryList", e)
        history.push("/logout")
      }
    }

    apiRequest()

 }, [ history, context.reloadCategoryProductList])

 const columns = [
  {
    title: 'Nombre',
    dataIndex: 'name',
    key: 'name',
    width: '50%',
    isSearchable: true
  },
  /*{
    title: 'Imagen',
    key: 'imagePath',
    width: '40%',
    render: (text, record) => (
      <Col md={10}>
        <Image src={record.imagePath} thumbnail />
      </Col>
    ),
  },*/
  {
    title: 'Estado',
    key: 'isEnabled',
    dataIndex: 'isEnabled',
    filters: [
      {
        text: 'Habilitado',
        value: true,
      },
      {
        text: 'Deshabilitado',
        value: false,
      },
    ],
    onFilter: (value, record) => record.isEnabled === value,
    render: (text, record) => (
      <Tag color={record.isEnabled ? "green" : "volcano"}>
        {record.isEnabled ? "Habilitado" : "Deshabilitado"}
      </Tag>
      )
  },
  {
    title: 'Actualizar',
    key: 'action',
    actionType: 'update',
    align: 'center'
  },
];

return(
  <CustomTable 
    loadingTable={loadingTable} 
    columns={columns} 
    dataSource={categoryList} 
    component={Category}
    title={ <h3 className="text-primary">categorías de productos</h3> }
    buttonText={"Crear Categoría"}
  />
)
}

export default CategoryList