import React, {useEffect, useState, useContext} from 'react'
import { Table, Input, Button, Space } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined, EditOutlined, DeleteOutlined,CloseCircleOutlined } from '@ant-design/icons';
import { Col, Modal, Container, Button as ButtonB, Row } from 'react-bootstrap';
import {Context} from '../../context/context'

import swal from 'sweetalert';

const CustomTable = (props) => {

  const [context, ] = useContext(Context)

  var searchText = ''
  var searchedColumn = ''
  const [columns, setColumns] = useState('')
  const [dataUpdate, setDataUpdate] = useState(null)
  const [showModalEdit, setshowModalEdit] = useState(false);

  const handleShowModalEdit = (dataUpdate) => {
    setDataUpdate(dataUpdate);
    setshowModalEdit(true);
  }
  const handleCloseModalEdit = () => setshowModalEdit(false);

  useEffect(() => {
    mapColumns()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.contentList, context.orderList])


  /**Manejo de columnas y filtros */
  const mapColumns = () => {

    let listcolumns = props.columns

    listcolumns = listcolumns.map(column => {
      if(column.isSearchable){
        delete column["isSearchable"]
        return {
          ...column, 
          ...getColumnSearchProps(column.dataIndex, column.title)
        }
      }else if(column.actionType === "update"){
        delete column["actionType"]
        return{
          ...column,
          render: (text, record) => (
            <Space size="middle">
              <EditOutlined style={{ fontSize: '20px', color: '#08c' }} onClick={() => handleShowModalEdit(record)}/>
            </Space>
          ),
        }
      }else if(column.actionType === "delete"){
        delete column["actionType"]
        return{
          ...column,
          render: (text, record) => (
            <Space size="middle">
              <DeleteOutlined  style={{ fontSize: '20px', color: '#eb2f96' }} onClick={() => handleDelete(props.handleDelete, record, props.dataFromParent)}/>
            </Space>
          ),
        }
      }
      else{
        return column
      }      
    })

    setColumns(listcolumns)
  }

  const getColumnSearchProps = (dataIndex, title) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Buscar ${title}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Aceptar
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reiniciar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),

    render: text => 
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    searchText = selectedKeys[0]
    searchedColumn = dataIndex
  };

  const handleReset = clearFilters => {
    clearFilters();
    searchText = ''
  };

  const handleDelete = async(handleDelete, record, dataFromParent) => {
    const confirmDelete = await swal({
      title: "Está seguro que desea eliminar este registro?",
      text: "Esta operación es irreversible",
      icon: "warning",
      buttons:  ["Cancelar", "Aceptar"],
      dangerMode: true,
    });
    if (confirmDelete) {
      try{
        await handleDelete(record, dataFromParent)
        //swal("Eliminación exitosa", `La información se ha eliminnado correctamente`, "success",{button:  "Aceptar"});
      }catch(error){
        console.error("Error al eliminar: ", error)
        //swal("Ha ocurrido un error", `La información no ha sido eliminada`, "error",{button:  "Aceptar"});
      }
    }
  }
  return(
    <div style={{marginRight: "0% !important"}}>
      {props.title &&(
      <Row>
        <Col sm={10}>
          {props.title}
        </Col>
        <Col sm={2}>
          <ButtonB variant="link" className="float-right" type="submit" onClick={() => handleShowModalEdit(null)}>
            {props.buttonText}
          </ButtonB>
        </Col>
      </Row>
      )}

      {props.titleOrderList &&(
        props.titleOrderList
      )}

     <Table 
      loading={props.loadingTable} 
      columns={columns} 
      dataSource={props.dataSource} 
      pagination={{ pageSize: 10 }} 
      scroll={{ x: 500, y: 440 }} 
      showSorterTooltip={false}
      locale={{
        filterConfirm: "Aceptar",
        filterReset: "Reiniciar",
        emptyText: "No se han encontrado datos"
        }
      }
    />

      {props.component &&
      <Modal size="lg" show={showModalEdit} onHide={handleCloseModalEdit}>
        <Container style={{padding: "20px"}}>
        <CloseCircleOutlined  className="float-right" onClick={handleCloseModalEdit} style={{ fontSize: '25px', color: '#08c' }}/>
          <props.component data={dataUpdate} dataFromParent={props.dataFromParent} closeModal={handleCloseModalEdit}/>
        </Container>
      </Modal>
      }
               
    </div>
  )
}

export default CustomTable