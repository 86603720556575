import React, {useEffect, useState, useContext} from 'react'
import { useHistory } from 'react-router-dom'
import * as RestService from '../../services/RestService'
import { Col, Modal, Container, Row} from 'react-bootstrap';
import {  CloseCircleOutlined } from '@ant-design/icons';

import moment from "moment"
import Coupon from './Coupon'
import CustomTable from '../utilities/CustomTable'
import {Context} from '../../context/context'
import { Tag  } from 'antd';
import Settings from '../settings-component/Settings'

const CouponList = () => {

  const history = useHistory()
  const [couponList, setCouponList] = useState([])
  const [loadingTable, setLoadingTable] = useState(true)
  const [context, ] = useContext(Context)
  const [showModal, setshowModal] = useState(false);


  useEffect(() => {

    const apiRequest = async () =>  {
      try{
        const couponResponse = await RestService.axiosClientGet('/service/coupon')
        let couponList = couponResponse.data
        couponList.map(coupon => {
          coupon.key = coupon.id
          if(coupon.valueType === "PERCENTAGE")
            coupon.valueType = "PORCENTAJE"
          else
            coupon.valueType = "VALOR EN DINERO"
        })
        setCouponList(couponList)
        setLoadingTable(false)
      }catch(e){
        console.error("error in CouponList", e)
        history.push("/logout")
      }
    }

    apiRequest()

 }, [ history, context.reloadCouponList])

 const columns = [
  {
    title: 'Código',
    dataIndex: 'code',
    key: 'code',
    width: '15%',
    isSearchable: true
  },
  {
    title: 'Tipo de cúpon',
    dataIndex: 'valueType',
    key: 'valueType',
    width: '15%',
    isSearchable: true
  },
  {
    title: 'Valor',
    dataIndex: 'value',
    key: 'value',
    width: '15%',
    isSearchable: true
  },
  {
    title: 'Cantidad Máxima ',
    dataIndex: 'maximumQuantity',
    key: 'maximumQuantity',
    width: '15%',
    isSearchable: true
  },
  {
    title: 'Total Redimidos',
    dataIndex: 'totalQuantity',
    key: 'totalQuantity',
    width: '15%',
    isSearchable: true
  },
  {
    title: 'Fecha Expiración',
    dataIndex: 'expirationDate',
    key: 'expirationDate',
    width: '15%',
    sorter: (a, b) => moment(a.expirationDate, "DD/MM/YYYY").unix() - moment(b.expirationDate, "DD/MM/YYYY").unix()
  },
  {
    title: 'Estado',
    key: 'state',
    width: '10%',
    dataIndex: 'state',
    filters: [
      {
        text: 'Habilitado',
        value: true,
      },
      {
        text: 'Deshabilitado',
        value: false,
      },
    ],
    onFilter: (value, record) => record.state === value,
    render: (text, record) => (
      <Tag 
        color={record.state === "ENABLED" ? "green" :
        record.state === "DISABLED" ? "volcano":
        record.state === "LIMITED_REACHED" ? "red":
        record.state === "EXPIRED" ? "magenta": "cyan"
      }>
        { record.state === "ENABLED" ? "Habilitado" :
          record.state === "DISABLED" ? "deshabilitado":
          record.state === "LIMITED_REACHED" ? "Limite Alcanzado":
          record.state === "EXPIRED" ? "Expirado": "Estado no Valido"
        }
      </Tag>
      )
    },
    {
      title: 'Actualizar',
      width: '15%',
      key: 'action',
      actionType: 'update',
      align: 'center'
    },
  ];

  const getTitle = () => {
    return (
      <Row>
        <Col sm={10}>
          <h3 className="text-primary">Cupones</h3>
        </Col>

        <Modal size="lg" show={showModal} onHide={() => setshowModal(false)}>
          <Container style={{padding: "20px"}}>
          <CloseCircleOutlined  className="float-right" onClick={() => setshowModal(false)} style={{ fontSize: '25px', color: '#08c' }}/>
            <Settings closeModal={() => setshowModal(false)}/>
          </Container>
        </Modal>

      </Row>
    )
  }


  return(
    <CustomTable 
      loadingTable={loadingTable} 
      columns={columns} 
      dataSource={couponList} 
      component={Coupon}
      title={getTitle()}
      buttonText={"Crear Cupón"}
    />
  )
}

export default CouponList