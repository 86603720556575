import React, {useEffect, useState, useContext} from 'react'
import { Spin } from 'antd';
import swal from 'sweetalert';
import { Form, Button, Row, Col} from 'react-bootstrap';
import * as RestService from '../../services/RestService'
import { useHistory } from 'react-router-dom'
import Select from 'react-select';
import AlertForm from '../utilities/AlertForm'
import {Context} from '../../context/context'
import cookie from 'js-cookie';

const SelectDeliverMan = ({data, closeModal}) => {

	const history = useHistory()
	const [context, setContext] = useContext(Context)
  const [deliveryManList, setDeliveryManList] = useState([]);
  const [deliveryManObject, setDeliveryManObject] = useState(null);
	const [isLoadingDeliveryManList, setIsLoadingDeliveryManList] = useState(false);
	const [firstLoad, setFirstLoad] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  
  
  useEffect(() => {
    const getDeliveryManList = async () =>  {
      setIsLoadingDeliveryManList(true)
      try{
        const office = JSON.parse(cookie.get("office"));
        const deliveryManListResponse = await RestService.axiosClientGet(`/service/users/delivery_man`)
        let deliveryManList = deliveryManListResponse.data
        deliveryManList.map(deliveryMan => {
          deliveryMan.value = deliveryMan.id;
          deliveryMan.label = deliveryMan.fullName;
          return deliveryMan;
        })
        setDeliveryManList(deliveryManList)
      }catch(e){
        console.error("error in SelectDeliverMan", e)
        history.push("/logout")
      }
      setIsLoadingDeliveryManList(false)
    }
    getDeliveryManList()
		if(data.deliveryMan){
			setDeliveryManObject({label: data.deliveryMan.name, value: data.deliveryMan.id})
		}
  } ,[data.deliveryMan, history])


  const onSubmmit = async(e) => {
		e.preventDefault()
		setFirstLoad(false)
    if(!deliveryManObject)
			return;

		setIsLoading(true)
		const deliveryManResponse = await RestService.axiosClientPost(`/service/order/update_delivery_man?deliveryManId=${deliveryManObject.value}&orderId=${data.id}`, null, true);
		if(deliveryManResponse){
			swal("Operación exitosa", `El domiciliario fue asignado a la orden`, "success",{button:  "Aceptar"});
		}else{
			swal("Ha ocurrido un error", `El domiciliario no fue asignado a la orden`, "error",{button:  "Aceptar"});  
		}
		setIsLoading(false)
		setContext({reloadOrderList: Math.random()})
		closeModal()
  }

  return (
    <div>
			<h3 className="text-primary">Asignar Domiciliario</h3>
      <hr/>

      <Form>
				<Form.Group as={Row}>
					<Form.Label column sm={3}>Selecciona el Domiciliario</Form.Label>
					<Col className={"z"}  sm={9}>
						{isLoadingDeliveryManList ? 
							<Spin className="float-right" size="large" /> 
						:( 
							<Select 
								placeholder={"Seleccionar Domiciliario"}
								value={deliveryManObject}
								style={{ zIndex:"99999 !important"}} 
								options={deliveryManList}  
								onChange={opt =>  opt.value ? setDeliveryManObject({label: opt.label, value: opt.value}) : setDeliveryManObject(null)}
							/> 
						)}
					</Col>
				</Form.Group>
			 	{!deliveryManObject && !firstLoad &&  <AlertForm message={"Debe Seleccionar un Domiciliario"}/>}

				<Form.Group>
					<Col sm={12}>
						{isLoading ? 
							<Spin className="float-right" size="large" /> 
						: 
							<Button variant="primary" className="float-right" type="submit" onClick={onSubmmit} disabled={isLoadingDeliveryManList}>
								Asignar Domiciliario
							</Button>
						}
					</Col>
				</Form.Group>
			</Form>
    </div>
  )

}

export default SelectDeliverMan;