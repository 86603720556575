import React from 'react'
import { BrowserRouter as Router } from "react-router-dom";



export default function Home(){

  return(
    <Router>
      <div className="App">
      <nav className="navbar navbar-expand-lg navbar-light fixed-top">
        <div className="container">


          <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
          </div>
        </div>
      </nav>
    </div>
  </Router>
  )
}