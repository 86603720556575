import React, {useEffect} from 'react'
import { Collapse, Descriptions } from 'antd';
import { Form, Row} from 'react-bootstrap';

const { Panel } = Collapse;

const ProductListResume = ({order}) => {

  useEffect(() => {
    console.log(order)
  }, [order])

  const header = (orderDetail) => {
    return (
      <>
      <span><b>Producto:  </b>{`${orderDetail.product.name}`}<b>  Cantidad:  </b>{`${orderDetail.productQuantity}`}</span>
      </>
    )
  }

  return (
    <div>
      <h3 className="text-primary">Resumen del pedido</h3>
      <hr/>
      {/*
      <Descriptions>
        <Descriptions.Item label="Cliente">{`${order.user.name} ${order.user.lastName}`}</Descriptions.Item>
        <Descriptions.Item label="Celular">{`${order.user.cellPhoneNumber}`}</Descriptions.Item>
        <Descriptions.Item label="Valor Total">{`$ ${order.totalPrice}`}</Descriptions.Item>
        <Descriptions.Item label="Fecha de pedido">{`${order.date}`}</Descriptions.Item>
        <Descriptions.Item label="Estado">{order.state === 'REQUESTED' ? "Solicitado" : order.state === 'CONFIRMED' ? "CONFIRMED" : order.state === 'IN_DELIVERY' ? "En reparto" : "Entregado"} </Descriptions.Item>
      </Descriptions>
      */}

      <Form.Group as={Row}>
        <Form.Label column sm={3}>Cliente</Form.Label>
        <Form.Label column sm={9}>{`${order.user.name} ${order.user.lastName}`}</Form.Label>
        <Form.Label column sm={3}>Celular</Form.Label>
        <Form.Label column sm={9}>{`${order.user.cellPhoneNumber}`}</Form.Label>
        <Form.Label column sm={3}>Dirección</Form.Label>
        <Form.Label column sm={9}>{`${order.address.address}`}</Form.Label>
        <Form.Label column sm={3}>Barrio</Form.Label>
        <Form.Label column sm={9}>{`${order.address.neighborhood}`}</Form.Label>
        <Form.Label column sm={3}>Cargo Adminisgtrativo</Form.Label> 
        <Form.Label column sm={9}>{`$ ${order.administrativeCharge}`}</Form.Label>
        <Form.Label column sm={3}>Propina</Form.Label> 
        <Form.Label column sm={9}>{`$ ${order.tip}`}</Form.Label>
        <Form.Label column sm={3}>Valor domicilio</Form.Label> 
        <Form.Label column sm={9}>{"$" + (order.shippingCost + order.shippingRevenue)}</Form.Label>
        <Form.Label column sm={3}>Sub Total</Form.Label>
        <Form.Label column sm={9}>{`$ ${order.subtotalPrice}`}</Form.Label>
        <Form.Label column sm={3}>Descuento por Cúpon</Form.Label>
        <Form.Label column sm={9}>{`$ ${order.totalCouponDiscount}`}</Form.Label>
        <Form.Label column sm={3}>Valor Total</Form.Label>
        <Form.Label column sm={9}>{`$ ${order.totalPrice}`}</Form.Label>
        <Form.Label column sm={3}>Fecha de pedido</Form.Label>
        <Form.Label column sm={9}>{`${order.date}`}</Form.Label>
        <Form.Label column sm={3}>Método de pago</Form.Label> 
        <Form.Label column sm={9}>{order.paymentMethod === 'CASH' ? "Pago en Efectivo" : order.state === 'QR' ? "Pago por QR" : "Pago por Datafono"} </Form.Label>
        <Form.Label column sm={3}>Con cuanto va a pagar</Form.Label> 
        <Form.Label column sm={9}>{`$ ${order.moneyToPay}`}</Form.Label>
        <Form.Label column sm={3}>Devuelta</Form.Label> 
        <Form.Label column sm={9}>{`$ ${order.remainingMoney}`}</Form.Label>
        <Form.Label column sm={3}>Observaciones</Form.Label> 
        <Form.Label column sm={9}>{order.note}</Form.Label>
        <Form.Label  className="text-primary" style={{textAlign: 'center'}} column sm={12}>Productos</Form.Label>
      </Form.Group>

      <Collapse >
      {
        order.orderDetailList.map(orderDetail => (
          <Panel header={header(orderDetail)} key={orderDetail.product.id}>
            <Form.Group as={Row}>
              <Form.Label  className="text-primary" style={{textAlign: 'center'}} column sm={12}>Contenido del producto</Form.Label>
            </Form.Group>
            {
              orderDetail.product.contentList.map(content => (
                <div key={content.id}>
                    <Descriptions>
                      <Descriptions.Item label="Cantidad">{`${content.quantity} (X${orderDetail.productQuantity})`}</Descriptions.Item>
                      <Descriptions.Item label="Nombre">{content.name}</Descriptions.Item>
                    </Descriptions>
                </div>
              ))
            }
          </Panel>
        ))
      }
    </Collapse>
  </div>
  )
}

export default ProductListResume