import React from "react";
import {Card} from "antd";

const TermsConditions = () => {

    return(
        <Card title="Términos y Condiciones">

  
            MODELO FORMATO DE AUTORIZACIÓN PARA EL TRATAMIENTO DE DATOS PERSONALES Y AVISO DE PRIVACIDAD
NOMBRE DE LA EMPRESA: A DOMICILIO TELEBROSTY S.A.S
CORREO ELECTRÓNICO: julieta-mc@hotmail.com  contabilidadjf@hotmail.com
TELÉFONO: 6049991
DIRECCIÓN: CL 50 No 51-47 LC 112 ITAGUI.
DESCRIPCIÓN DE LA FINALIDAD PARA LA CUAL SE UTILIZARÁN LOS DATOS RECOGIDOS: 

Declaro de manera libre, expresa, inequívoca e informada, que AUTORIZO a A DOMICILIO TELEBROSTY S.A.S  . para que, en los términos del literal a) del artículo 6 de la Ley 1581 de 2012, realice la recolección, almacenamiento, uso, circulación, supresión, y en general, tratamiento de mis datos personales, incluyendo datos sensibles, como mis huellas digitales, fotografías, videos y demás datos que puedan llegar a ser considerados como sensibles de conformidad con la Ley, para que dicho Tratamiento se realice con el fin de lograr las finalidades relativas al presente contrato y en especial participar y   ser informado de eventos y promociones y recibir invitaciones e información relativa a las actividades de A DOMILICIO TELEBROSTY S.A.S  Declaro que se me ha informado de manera clara y comprensible que tengo derecho a conocer, actualizar y rectificar los datos personales proporcionados, a solicitar prueba de esta autorización, a solicitar información sobre el uso que se le ha dado a mis datos personales, a presentar quejas ante la Superintendencia de Industria y Comercio por el uso indebido de mis datos personales, a revocar esta autorización o solicitar la supresión de los datos personales suministrados y a acceder de forma gratuita a los mismos. Declaro que conozco y acepto el Manual de Tratamiento de Datos Personales de A DOMICILIO TELEBROSTY S.A.S., y que la información por mí proporcionada es veraz, completa, exacta, actualizada y verificable. Mediante la firma del presente documento, manifiesto que reconozco y acepto que cualquier consulta o reclamación relacionada con el Tratamiento de mis datos personales podrá ser elevada por escrito ante A DOMICILIO TELEBROSTY S.A.S, como Responsable del Tratamiento, según las indicaciones establecidas en A DOMICILIO TELEBROSTY





AVISO DE PRIVACIDAD Le recordamos la posibilidad que tiene de acceder en cualquier momento a los datos suministrados, así como de solicitar la corrección, actualización o supresión, en los términos establecidos por la Ley 1581 de 2012, dirigiendo una comunicación escrita al responsable de tratamiento a la dirección arriba indicada con los siguientes datos: nombre y apellidos, domicilio a efectos de notificaciones, petición en que se concreta la solicitud, fecha, firma de la persona interesada. Sus datos personales serán incluidos en una base de datos y serán utilizados para las siguientes finalidades:
•Lograr una eficiente comunicación relacionada con nuestros productos, servicios, ofertas, promociones, alianzas, estudios, concursos, contenidos, así como los de nuestras compañías vinculadas, y para facilitarle el acceso general a la información de éstos.
•Proveer nuestros servicios y productos. 
•Informar sobre nuevos productos o servicios que estén relacionados con el o los contratado(s) o adquirido(s). 
•Dar cumplimiento a obligaciones contraídas con nuestros clientes, proveedores, y empleados. 
•Informar sobre cambios de nuestros productos o servicios. 
•Evaluar la calidad del servicio. 
•Realizar estudios internos sobre hábitos de consumo. Se le informa a los titulares de información que pueden consultar el Manual Interno de Políticas y Procedimientos de Datos Personales de A DOMICILIO TELEBROSTY S.A.S, que contiene nuestras políticas para el tratamiento de la información recogida, así como los procedimientos de consulta y reclamación que le permitirán hacer efectivos sus derechos al acceso, consulta, rectificación, actualización y supresión de los datos.

Acepto 
</Card>
    )
}

export default TermsConditions;