import React, {useEffect, useState} from 'react'
import { useForm } from "react-hook-form";
import { Form, Button, Row, Col } from 'react-bootstrap';
import AlertForm from '../utilities/AlertForm'
import { useHistory } from 'react-router-dom'
import { Spin } from 'antd';
import * as RestService from '../../services/RestService'

const Settings = (props) => {

  const history = useHistory()
  const { handleSubmit, register, errors, setValue } = useForm();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(()=> {

    const apiRequest = async () =>  {
      setIsLoading(true)
      try{
        const settingResponse = await RestService.axiosClientGet('/service/setting?name=BANNER_TITLE')
        setValue("title", settingResponse.data.value)
        setValue("id", settingResponse.data.id)
      }catch(e){
        console.error("error in Settings", e)
        history.push("/logout")
      }
      setIsLoading(false)
    }

    apiRequest()

  }, [history, setValue])

  const onSubmmit = async data => {
    setIsLoading(true)
    var requestData = {};
    requestData.id = data["id"];
    requestData.value = data["title"];
    await RestService.axiosClientPut('/service/setting', requestData, 'título del bannner')
    setIsLoading(false)
    props.closeModal()

  }
  

  return(
    <Form onSubmit={handleSubmit(onSubmmit)}>

      <Form.Group>
        <h3 className="text-primary">Actualizar título</h3>
        <hr/>
      </Form.Group>
      <Form.Control
        type="hidden"
        name="id"
        ref={register({required: true})}
      />

      <Form.Group as={Row}>
        <Form.Label column sm={2}>Título</Form.Label>
        <Col sm={10}>
          <Form.Control 
            ref={register({
              required: "Ingrese el título",
              maxLength: 40
            })}
            placeholder="Título de banners" 
            type="text" 
            name="title"/>
        </Col>
      </Form.Group>

      {errors.title && errors.title.type === "required" && <AlertForm message={errors.title.message}/>}
      {errors.title && errors.title.type === "maxLength" && <AlertForm message={"El número máximo de caracteres es de 40"}/>}

      <Form.Group>
        <Col sm={12}>
          {isLoading ? 
            <Spin className="float-right" size="large" /> 
          : 
            <Button variant="primary" className="float-right" type="submit">
              Actualizar título
            </Button>
          }
        </Col>
      </Form.Group>
    </Form>
  ) 

}

export default Settings;