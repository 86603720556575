import React, { useEffect, useState } from 'react'
import { Form, Button, Row, Col } from 'react-bootstrap';
import swal from 'sweetalert';
import AlertForm from '../utilities/AlertForm'
import { Spin, Popconfirm } from 'antd';
import Select from 'react-select';
import { useForm } from "react-hook-form";
import * as RestService from '../../services/RestService'
import { QuestionCircleOutlined } from '@ant-design/icons';

const Setting = () => {

  const { handleSubmit, register, errors, reset, setValue } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [configurationList, setConfigurationList] = useState([
    { value: 'ADMINISTRATIVE_CHARGE',label: 'Tarifa de Servicio',  description: 'Es el valor del costo del servicio que es incluido por cada orden, el valor ingresado debe ser solo numerico' },
    { value: 'SHIPPING_COST',label: 'Costo de domicilio para los asaderos',  description: 'Es el valor del costo del domicilio de los asaderos, este valor sumado con la configuración "Ganancia por domicilio" es el valor del domicilio total por cada orden' },
    { value: 'SHIPPING_REVENUE',label: 'Ganancia por domicilio',  description: 'Es el valor de la ganancia por domicilio que recibe la app, este valor sumado con la configuración "Costo de domicilio para los asaderos" es el valor del domicilio total por cada orden' },
  ]);
  const [configuration, setConfiguration] = useState();
  const [configurationSelected, setConfigurationSelected] = useState();

  const onSubmmit = async data => {

    console.log("data, ", data);
    console.log("configurationSelected", configurationSelected);
    setIsLoading(true)
    const response = await RestService.axiosClientPut('/service/setting', {
      "name":  configurationSelected.name,
      "value": data.value
    }, false, true);
    if (response == null) {
      swal("Ha ocurrido un error", `La configuración no fue actualizada`, "error", { button: "Aceptar" });
    } else {
      swal("Operación exitosa!", `La configuración fue actualizada correctamente`, "success", { button: "Aceptar" });
    }
    setIsLoading(false)
  }

  useEffect(() => {
    const getSettingInformation = async () =>{
      setIsLoading(true);
      if(configuration?.value){
        const settingResponse = await RestService.axiosClientGet(`/service/setting?name=${configuration.value}`)
        setConfigurationSelected(settingResponse.data)
        setValue("value", settingResponse.data.value)
      }
      setIsLoading(false);
    }
    getSettingInformation();

  }, [configuration])

  return (
    <div style={{ marginRight: "0% !important", padding: "15px"}}>
      <Form onSubmit={handleSubmit(onSubmmit)}>

        <Form.Group>
          <h3 className="text-primary">Configuraciones Generales</h3>
          <hr />
        </Form.Group>

        <Form.Group as={Row}>
          <Col sm={12}>
            <Select
              mode="multiple"
              placeholder="Seleccionar Configuración"
              value={configuration}
              onChange={(value) => setConfiguration(value)}
              options={configurationList}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row}>
          <Form.Label column >Descripción</Form.Label>
          <Col sm={12}>
            <Form.Control
              as="textarea"
              style={{height: "9em"}}
              placeholder="Descripción"
              name="messageBody" 
              value={configuration?.description}
              disabled
              />
          </Col>
        </Form.Group>

        <Form.Group as={Row}>
          <Form.Label column >Valor</Form.Label>
          <Col sm={12}>
            <Form.Control
              ref={register({
                required: "Debe ingresar el nuevo valor para la configuración",
              })}
              as="input"
              placeholder="Nuevo Valor para la configuración"
              name="value" 
              />
          </Col>
        </Form.Group>

        {errors.value && <AlertForm message={errors.value.message} />}

        <Form.Group>
          <Col >
            {isLoading ?
              <Spin className="float-right" size="large" />
              :
              <Popconfirm
                title={"Está seguro de actualizar la configuración?, esta acción puede cambiar todas las ordenes que serán creadas?"}
                icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                onConfirm={() => handleSubmit(onSubmmit)()}
              >
                <Button variant="primary" className="float-right" type="submit" disabled={!configuration}>
                  Actualizar Configuración
                </Button>
              </Popconfirm>
            }
          </Col>
        </Form.Group>
      </Form>
    </div>
  )
}

export default Setting;