import React, {useContext} from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from './components/auth/Login'
import TermsConditions from "./components/terms-conditions/TermsConditions"
import Home from './components/home/Home'
import Order from './components/order/Order'
import OrderReport from './components/reports/OrderReport'
import UserReport from './components/reports/UserReport'
import Chat from './components/chat/Chat'
import UsersList from "./components/users/UsersList"
import Administrate from './components/administrate/Administrate'
import PrivateRoute from './components/security/PrivateComponent'
import Header from './components/home/Header'
import Logout from './components/auth/Logout'
import {Context, Provider} from './context/context'
import { Layout } from 'antd';
import 'antd/dist/antd.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

function App() {

  const [context, setContext] = useContext(Context)

  return (
    <Router>
      <Provider value={[context, setContext]}>
        <div className="App">
        <Header/>
          <Layout className={"body-app"}>
            <Switch>
              <Route exact path="/login" component={Login} />
              <Route exact path="/logout" component={Logout}/>
              <PrivateRoute exact path='/' component={Order} roles={["ROLE_OPERATOR"]}/>
              <PrivateRoute exact path="/home" component={Home} roles={["ROLE_OPERATOR", "ROLE_ADMIN"]}/>
              <PrivateRoute exact path="/order" component={Order} roles={["ROLE_OPERATOR"]}/>
              <PrivateRoute exact path="/chat" component={Chat} roles={["ROLE_SUPPORT"]}/>
              <PrivateRoute exact path="/delivery" component={() => <UsersList isOnlyForDeliveryUsers/>} roles={["ROLE_OPERATOR"]}/>
              <PrivateRoute  path="/administrate-app" component={Administrate} roles={["ROLE_ADMIN"]}/>
              <PrivateRoute  path="/order-report" component={OrderReport} roles={["ROLE_ADMIN"]}/>
              <PrivateRoute  path="/user-report" component={UserReport} roles={["ROLE_ADMIN"]}/>
              {/*<Route path="/sign-up" component={SignUp} />*/}
              <Route exact path="/terms-conditions" component={TermsConditions} />
              <Route component={Login} />
            </Switch>
          </Layout>
      </div>
    </Provider>
  </Router>
  );
}

export default App;
