import React, {useState} from 'react';
import { Link } from "react-router-dom";
import * as RestService from '../../services/RestService'
import { useForm } from "react-hook-form";
import { Alert, Image } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import cookie from 'js-cookie';
import { Spin } from 'antd';
import { fire } from "../../config/firebase";
import swal from 'sweetalert';
import {axiosClientLogin} from '../../config/axios';
import './auth.css';
const qs = require('querystring')

const Login = () => {
  
  const { handleSubmit, register, errors } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory()


  const doLogin = async credentials => {
    
    setIsLoading(true)

    credentials = {
      ...credentials,
      "grant_type": "password"
    }

    try{
      const response = await axiosClientLogin.post('/oauth/token', qs.stringify(credentials));
      loadDataInCookies(response.data);
      await loadSettings();
      const firebaseToken = await RestService.axiosClientGet(`/service/firebase/user_get_token/${response.data.id.toString()}`);
      const result = await fire.auth().signInWithCustomToken(firebaseToken.data);
      if(response.data.roles[0].authority === 'ROLE_ADMIN'){
        history.push("/administrate-app")
      }else if (response.data.roles[0].authority === 'ROLE_OPERATOR'){
        history.push("/order")
      }else{
        history.push("/chat")
      }
      
    }catch(error){
      console.error("Error in doLogin:", error)
      swal("Ha ocurrido un error", "Usuario o password incorrecto", "error",{button:  "Aceptar"});
    }
    setIsLoading(false)
  }

  const loadSettings = async () => {

    try{
      const response = await RestService.axiosClientGet('/service/setting/miliseconds_sound_orders');
      cookie.set("miliseconds_sound_orders", response.data.value);
    }catch(error){
      console.error("Error in loadSettings:", error)
    }
  }

  const loadDataInCookies = (data) => {
    cookie.set("token", data.access_token);
    cookie.set("refreshToken", data.refresh_token)
    cookie.set("fullName", data.full_name);
    cookie.set("name", data.name);
    cookie.set("lastName", data.last_name);
    cookie.set("userName", data.user_name);
    cookie.set("cellPhoneNumber", data.cell_phone_number.toString());
    cookie.set("id", data.id.toString());
    cookie.set("uuid", data.uuid);
    const roles = data.roles.map(rol => rol.authority)
    cookie.set("roles", JSON.stringify(roles));
    cookie.set("office",data.office)

  }

  return(
    <div className="auth-wrapper" style={{marginTop: '5%', background: 'none !important'}}>
      <div className="auth-inner">
        
        <form onSubmit={handleSubmit(doLogin)}>
          <div className="container">
            <div className="row">
              <Image src="logo_brosty_new.png" className="login-image"  />
            </div>
          </div>

          <h3>Iniciar Sesión</h3>

          <div className="form-group">
              <label>Correo Electrónico</label>
              <input 
                ref={register({
                  required: "El correo electrónico es obligatorio",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: "Correo electrónico no válido"
                  }
                })}
                //defaultValue="admin@brosty.com"
                name="username" 
                type="text" 
                className="form-control" 
                placeholder="Ingrese el email" />
          </div>

          {errors.username && <Alert variant={"danger"}>{errors.username.message}</Alert>}

          <div className="form-group">
              <label>Contraseña</label>
              <input 
                ref={register({
                  required: "La Contraseña es obligatoria",
                })}
                name="password" 
                type="password" 
                className="form-control" 
                placeholder="Ingrese la contraseña" />
          </div>

          {errors.password && <Alert variant={"danger"}>{errors.password.message}</Alert>}

          {/*
          <div className="form-group">
              <div className="custom-control custom-checkbox">
                  <input type="checkbox" className="custom-control-input" id="customCheck1" />
                  <label className="custom-control-label" htmlFor="customCheck1">Remember me</label>
              </div>
          </div>
          */}
          {isLoading ? 
            <Spin size="large" /> 
          : 
            <button type="submit" className="btn btn-primary btn-block">Ingresar</button>
          }
           {
          <p className="forgot-password text-right">
            <Link to={"/terms-conditions"}>Ver términos y condiciones</Link>
          </p>
        }
      </form>
      </div>
    </div>
  )
}

export default Login;