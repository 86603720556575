import React, {useEffect, useState, useContext} from 'react'
import { useHistory } from 'react-router-dom'
import * as RestService from '../../services/RestService'
import { Col, Modal, Container, Row} from 'react-bootstrap';
import {  CloseCircleOutlined } from '@ant-design/icons';

import Content from './Content'
import CustomTable from '../utilities/CustomTable'
import {Context} from '../../context/context'
import Settings from '../settings-component/Settings'

const ContentList = (props) => {

  const history = useHistory()
  const [contentList, setContentList] = useState([])
  const [loadingTable, setLoadingTable] = useState(true)
  const [context, setContext] = useContext(Context)
  const [showModal, setshowModal] = useState(false);


  useEffect(() => {

    const apiRequest = async () =>  {
      try{
        if(props.productId){
          const contentResponse = await RestService.axiosClientGet(`/service/content/${props.productId}`)
          let contentList = contentResponse.data
          contentList.map(content => content.key = content.id)
          setContentList(contentList)
        }else{
          setContentList(JSON.parse(JSON.stringify(context.contentList)))
        }

        setLoadingTable(false)
      }catch(e){
        console.error("error in ContentList", e)
        history.push("/logout")
      }
    }


    apiRequest()

 // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [context.reloadContentList, context.reloadContentListDelete, props.productId, context.contentList])

 const columns = [
  {
    title: 'Nombre',
    dataIndex: 'name',
    key: 'name',
    width: '15%',
    isSearchable: true
  },
  {
    title: 'Cantidad',
    dataIndex: 'quantity',
    key: 'quantity',
    width: '15%',
    isSearchable: true
  },
  {
    title: 'Actualizar',
    width: '15%',
    key: 'action',
    actionType: 'update',
    align: 'center'
  },
  {
    title: 'Eliminar',
    width: '15%',
    key: 'action',
    actionType: 'delete',
    align: 'center'
  },
  ];

  const getTitle = () => {
    return (
      <Row>
        <Col sm={10}>
          <h3 className="text-primary">Contenido del Producto</h3>
        </Col>

        <Modal size="lg" show={showModal} onHide={() => setshowModal(false)}>
          <Container style={{padding: "20px"}}>
          <CloseCircleOutlined  className="float-right" onClick={() => setshowModal(false)} style={{ fontSize: '25px', color: '#08c' }}/>
            <Settings closeModal={() => setshowModal(false)}/>
          </Container>
        </Modal>

      </Row>
    )
  }

  const handleDelete = async (data, dataFromParent) => {
    if(data.id){
      await RestService.axiosClientDelete(`/service/content/${data.id}`, 'contenido');
      setContext({reloadContentListDelete: Math.random()});
    }
    else{
      let contentListState = JSON.parse(JSON.stringify(context.contentList));
      contentListState = contentListState.filter(content => content.key !== data.key);
      setContext({contentList: contentListState})
    }
  }

  return(
    <CustomTable 
      loadingTable={loadingTable} 
      columns={columns} 
      dataSource={contentList} 
      component={Content}
      title={getTitle()}
      buttonText={"Crear Contenido"}
      handleDelete={handleDelete}
      dataFromParent={{productId: props.productId}}
    />
  )
}

export default ContentList