
import React, { useState, useContext } from 'react'
import { Form, Button, Row, Col } from 'react-bootstrap';
import swal from 'sweetalert';
import { useForm } from "react-hook-form";
import { Spin, Popconfirm } from 'antd';
import AlertForm from '../utilities/AlertForm'
import * as RestService from '../../services/RestService'
import { Context } from '../../context/context'
import { QuestionCircleOutlined} from '@ant-design/icons';

const selectOptions = [
  { value: 'PERCENTAGE', label: 'PORCENTAJE' },
  { value: 'CASH', label: 'VALOR EN DINERO' }
]

const PushNotifications = (props) => {

  const { handleSubmit, register, errors, reset } = useForm();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmmit = async data => {

    setIsLoading(true)
    const response = await RestService.axiosClientPost('/service/push_notification/all_users', data, false, true);
    if (response == null) {
      swal("Ha ocurrido un error", `La notificación no ha sido enviada`, "error", { button: "Aceptar" });
    }else{
      swal("Operación exitosa!", `La notificación 📢 ha sido enviada correctamente`, "success", { button: "Aceptar" });
    }
    reset()
    setIsLoading(false)

  }

  return (
    <div style={{marginRight: "0% !important", padding: "15px"}}>
      <Form onSubmit={handleSubmit(onSubmmit)}>

        <Form.Group>
          <h3 className="text-primary">Envío de notificaciones push</h3>
          <hr />
        </Form.Group>

        <Form.Group as={Row}>
          <Form.Label column >Titulo del mensaje</Form.Label>
          <Col sm={12}>
            <Form.Control
              ref={register({
                required: "Ingrese el titulo del mensaje",
              })}
              as="input"
              placeholder="Titulo del mensaje"
              name="messageTitle" />
          </Col>
        </Form.Group>

        {errors.messageTitle && <AlertForm message={errors.messageTitle.message} />}

        <Form.Group as={Row}>
          <Form.Label column >Cuerpo del mensaje</Form.Label>
          <Col sm={12}>
            <Form.Control
              ref={register({
                required: "Ingrese el cuerpo del mensaje",
              })}
              as="textarea"
              placeholder="Cuerpo del mensaje"
              name="messageBody" />
          </Col>
        </Form.Group>

        {errors.messageBody && <AlertForm message={errors.messageBody.message} />}

        <Form.Group>
          <Col >
            {isLoading ?
              <Spin className="float-right" size="large" />
              :
              <Popconfirm
                title={"Está seguro de enviar este mensaje a todos los usuarios?"}
                icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                onConfirm={() => handleSubmit(onSubmmit)()}
              >
                <Button variant="primary" className="float-right" type="submit">
                  Enviar mensaje push
                </Button>
              </Popconfirm>
            }
          </Col>
        </Form.Group>
      </Form>
    </div>
  )
}

export default PushNotifications