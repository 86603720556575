import axios from 'axios';
import cookie from 'js-cookie';

//LOCAL
//const BASE_URL = 'http://localhost:8080'

//TEST
//const BASE_URL = 'https://brosty.herokuapp.com'


//PRODUCCION
const BASE_URL = 'https://app.brosty.com'

const axiosClientLogin = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type':'application/x-www-form-urlencoded',
    "Authorization" : `Basic aWRfY2xpZW50ZToxMjM0NQ==`
  }
});

const axiosClient = axios.create({
  baseURL: BASE_URL
});

/*
axiosClient.interceptors.response.use(response => {
  return response;
}, err => {
  console.log("interceptor:::", err)
  console.log(err.config)
});*/

axiosClient.interceptors.response.use(response => {
  return response;
}, err => {
  return new Promise((resolve, reject) => {
      const originalReq = err.config;

      if ( err.response.status === 401 && err.config && !err.config.__isRetryRequest ){
          originalReq._retry = true;
          const refreshToken = cookie.get("refreshToken")

          let res = fetch(`${BASE_URL}/oauth/token?refresh_token=${refreshToken}&grant_type=refresh_token`, {
              method: 'POST',
              headers: {
                'Content-Type':'application/x-www-form-urlencoded',
                "Authorization" : `Basic aWRfY2xpZW50ZToxMjM0NQ==`
              },
          }).then(res => res.json()).then(res => {
              loadDataInCookies(res)
              originalReq.headers['Authorization'] = `Bearer ${res.access_token}`;
              return axios(originalReq);
          }).catch(err => console.warn("interceptors err:",err));
          resolve(res);
      }
      throw err;
  });
});

const loadDataInCookies = (data) => {
  console.log("data.access_token:::::", data.access_token)
  cookie.set("token", data.access_token);
  cookie.set("refreshToken", data.refresh_token)
  cookie.set("fullName", data.full_name);
  cookie.set("name", data.name);
  cookie.set("lastName", data.last_name);
  cookie.set("userName", data.user_name);
  cookie.set("cellPhoneNumber", data.cell_phone_number.toString());
  cookie.set("id", data.id.toString());
  cookie.set("uuid", data.uuid);
  const roles = data.roles.map(rol => rol.authority)
  cookie.set("roles", JSON.stringify(roles))
}

export {axiosClientLogin, axiosClient};