import React, {useState} from 'react'

const Context = React.createContext([{}, () => {}]);

const Provider  = props => {
    const [context, setContext] = useState({
      reloadBannerList: false,
      reloadCategoryProductList: false,
      reloadProductList: false,
      reloadUsersList: false,
      reloadCouponList: false,
      reloadToppingCategoryList: false,
      reloadContentList: false,
			reloadContentListDelete: 0,
			reloadOrderList: 0,
			contentList: [],
      orderList: 0,
      reloadOfficeList: false
    })

    return (
      <Context.Provider value={[context, setContext]}>
        {props.children}
      </Context.Provider>
    )
}

export {Context, Provider}