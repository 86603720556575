import React, { useEffect, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { PrinterOutlined } from '@ant-design/icons';
import './PrintOrder.css';

const maxCaracteres = 42;

const validateMaximumCharacters = (text, _maxCaracteres) => {
  if (text.length > _maxCaracteres) {
    text = text.slice(0, 39) + '...'
  }
  return text;
}

const calculateTotalProducts = (order) => {
  let total = 0;
  order.orderDetailList.map(detail => {
    total += detail.price * detail.productQuantity;
  })
  return total;
}

const FormatCurrency = (num) => {
  if (num) {
    return "$" + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
  } else {
    return 0;
  }
}


class OrderToPrint extends React.Component {
  render() {
    const { order } = this.props
    return (
      <div key={order.id} className='print-source'>
        <div className="ticket">
          <h4 className="centrado">BROSTY APP</h4>
          <p className="centrado">Pedido realizado por app movil
            <br /> {`Punto de Venta: ${order.office.name}`}
            <br />{`Consecutivo: ${order.id}`}</p>
          <br />
          <p>
            {validateMaximumCharacters(`Fecha de pedido: ${order.date}`, maxCaracteres)}<br />
            {validateMaximumCharacters(`Cliente: ${order.user.name} ${order.user.lastName}`, maxCaracteres)}<br />
            {validateMaximumCharacters(`Celular: ${order.user.cellPhoneNumber}`, maxCaracteres)}<br />
            {validateMaximumCharacters(`Direccion: ${order.address.address}`, maxCaracteres)}<br />
            {validateMaximumCharacters(`Barrio: ${order.address.neighborhood}`, maxCaracteres)}<br />
            {validateMaximumCharacters(`Unidad: ${order.address.residential}`, maxCaracteres)}<br />
            {validateMaximumCharacters(`Torre: ${order.address.tower}`, maxCaracteres)}<br />
            {validateMaximumCharacters(`Punto de Referencia: ${order.address.referencePoint}`, maxCaracteres)}<br />
            {validateMaximumCharacters('Forma de Pago: ' + (order.paymentMethod === 'CASH' ? "Pago en Efectivo" : order.paymentMethod === 'QR' ? "Pago por QR" : "Pago por Datafono"), maxCaracteres)}<br />
          </p>
        </div>
        <table className="print-table">
          <thead>
            <tr>
              <th className="cantidad print">PRODUCTO</th>
              <th className="producto print">CANTIDAD</th>
              <th className="precio print">TOTAL</th>
            </tr>
          </thead>
          <tbody>
            {order.orderDetailList.map(orderDetail => (
              <React.Fragment key={orderDetail.id}>
                <tr>
                  <td className="producto print"> {validateMaximumCharacters(`${orderDetail.product.name}`, 18)}</td>
                  <td style={{ textAlign: "center", verticalAlign: "middle" }} className="cantidad print"> {validateMaximumCharacters(`${orderDetail.productQuantity}`, 12)}</td>
                  <td className="precio print"> {validateMaximumCharacters(FormatCurrency(orderDetail.price), 10)}</td>
                </tr>
                {
                  orderDetail.product.contentList.map(content =>
                    <tr key={content.id + '-' + orderDetail.id}>
                      <td className="especificacion" colSpan="3"> {validateMaximumCharacters(`${content.quantity + ' ' + content.name}`, maxCaracteres)}</td>
                    </tr>
                  )
                }
              </ React.Fragment>
            ))}

            <tr>
              <td className="producto print">{validateMaximumCharacters(``, 5)}</td>
              <td style={{ textAlign: "center", verticalAlign: "middle", fontWeight: "500" }} className="cantidad print"> {validateMaximumCharacters(`Total Productos:`, 20)}</td>
              <td style={{ fontWeight: "500" }} className="precio print">{validateMaximumCharacters(FormatCurrency(calculateTotalProducts(order)), 15)}</td>
            </tr>

          </tbody>

        </table>

        <table><tbody><tr><td className="tr" /></tr></tbody></table>

        <br />

        <h5 className="print-color">Resumen Facturacion</h5>

        <table className="print-table">
          <tbody>
            <tr>
              <td className="key print">{validateMaximumCharacters(`Cargo administrativo:`, 25)}</td>
              <td className="value print">{validateMaximumCharacters(FormatCurrency(order.administrativeCharge), 15)}</td>
            </tr>
            <tr>
              <td className="key print">{validateMaximumCharacters(`Propina:`, 25)}</td>
              <td className="value print ">{validateMaximumCharacters(FormatCurrency(order.tip), 15)}</td>
            </tr>
            <tr>
              <td className="key print">{validateMaximumCharacters(`Valor Domicilio:`, 25)}</td>
              <td className="value print ">{validateMaximumCharacters(FormatCurrency(order.shippingCost + order.shippingRevenue), 15)}</td>
            </tr>
            <tr>
              <td className="key print">{validateMaximumCharacters(`Sub Total:`, 25)}</td>
              <td className="value print ">{validateMaximumCharacters(FormatCurrency(order.subtotalPrice), 15)}</td>
            </tr>
            <tr>
              <td className="key print">{validateMaximumCharacters(`Descuento por cupon:`, 25)}</td>
              <td className="value print ">{validateMaximumCharacters(FormatCurrency(order.totalCouponDiscount), 15)}</td>
            </tr>
            <tr>
              <td style={{ fontWeight: "500" }} className="key print">{validateMaximumCharacters(`Valor Total:`, 25)}</td>
              <td style={{ fontWeight: "500" }} className="value print ">{validateMaximumCharacters(FormatCurrency(order.totalPrice), 15)}</td>
            </tr>
          </tbody>
        </table>

        <table><tbody><tr><td className="tr" /></tr></tbody></table>
        <br />
        {!order.storePickup &&
          <table className="print-table">
            <tbody>
                <tr>
                  <td className="key">{validateMaximumCharacters(`Con cuanto se va a pagar:`, 25)}</td>
                  <td className="value ">{validateMaximumCharacters(FormatCurrency(order.moneyToPay), 15)}</td>
                </tr>
                <tr>
                  <td className="key">{validateMaximumCharacters(`Valor a devolver:`, 25)}</td>
                  <td className="value ">{validateMaximumCharacters(FormatCurrency(order.remainingMoney), 15)}</td>
                </tr>
            </tbody>
          </table>
        }
        <br />
      </div>
    );
  }
}


const PrintOrder = ({ order }) => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div style={{ display: "inline-block" }}>
      <OrderToPrint ref={componentRef} order={order} />
      <PrinterOutlined style={{ color: '#3282b8' }} onClick={handlePrint} />
    </div>
  );
};



export default PrintOrder;