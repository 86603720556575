
import React, {useState, useEffect, useContext} from 'react'
import { Form, Button, Row, Col } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import locale_es from "date-fns/locale/es"; 
import { useForm } from "react-hook-form";
import moment from "moment"
import { Spin, Checkbox, Tag } from 'antd';
import AlertForm from '../utilities/AlertForm'
import * as RestService from '../../services/RestService'
import {Context} from '../../context/context'
import Select from 'react-select';


const selectOptions = [
  { value: 'PERCENTAGE', label: 'PORCENTAJE' },
  { value: 'CASH', label: 'VALOR EN DINERO' }
]

const Coupon = (props) => {

  const { handleSubmit, register, errors, setValue } = useForm();
  const [isEnabled, setIsEnabled] = useState(true);
  const [expirationDate, setExpirationDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [context, setContext] = useContext(Context)
  const [valueType, setValueType] = useState(null);

  useEffect(() => {

    if(props.data){
      setValue('code', props.data.code);
      setValue('value', props.data.value);
      setValue('maximumQuantity',props.data.maximumQuantity);
      setValue("description", props.data.description)
      setExpirationDate(moment(props.data.expirationDate, "DD/MM/YYYY").toDate())
      setIsEnabled(props.data.state === 'ENABLED')
      if(props.data.valueType === "PORCENTAJE")
        setValueType({value: "PERCENTAGE", label: props.data.valueType})
      else
        setValueType({value: "CASH", label: props.data.valueType})
      setIsUpdate(true)
    }

  }, [props.data, setValue])


  const onSubmmit = async data => {
    
    if( !expirationDate )
      return;

    var requestData = {};

    requestData.code = data["code"];
    requestData.value = data["value"];
    requestData.maximumQuantity = data["maximumQuantity"];
    requestData.state = isEnabled ? 'ENABLED': 'DISABLED';
    requestData.expirationDate = moment(expirationDate).format("DD/MM/YYYY");
    requestData.valueType = valueType.value;

    setIsLoading(true)
    if(isUpdate){
      requestData.id = props.data.id;
      await RestService.axiosClientPut('/service/coupon', requestData, 'cupón')
    }else{
      await RestService.axiosClientPost('/service/coupon', requestData, 'cupón')
    }
    setIsLoading(false)
    props.closeModal()
    setContext({reloadCouponList: !context.reloadCouponList})

  }




  return(
      <Form onSubmit={handleSubmit(onSubmmit)}>

      <Form.Group>
        <h3 className="text-primary">{isUpdate ? 'Actualizar': 'Registrar'} Cupón</h3>
        <hr/>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={3}>Código</Form.Label>
        <Col sm={9}>
          <Form.Control 
            ref={register({
              required: "Ingrese el código del cupón",
            })}
            as="input" 
            placeholder="Código" 
            name="code"/>
        </Col>
      </Form.Group>

      {errors.code && <AlertForm message={errors.code.message}/>}

      <Form.Group as={Row}>
        <Form.Label column sm={3}>Selecciona el tipo de cupón</Form.Label>
        <Col className={"z"}  sm={9}>
            <Select 
              placeholder={"Seleccione el tipo de cupón"}
              value={valueType}
              style={{ zIndex:"99999 !important"}} 
              options={selectOptions}  
              onChange={opt =>  opt.value ? setValueType({label: opt.label, value: opt.value}) : setValueType(null)}
            /> 
        </Col>
      </Form.Group>

      {!valueType &&  <AlertForm message={"Debe Seleccionar el tipo de valor que tendrá el cúpon"}/>}


      <Form.Group as={Row}>
        <Form.Label column sm={3}>Valor</Form.Label>
        <Col sm={9}>
          <Form.Control 
            ref={register({
              required: "Ingrese el valor del cupón ya sea en porcentaje o en valor de dinero",
              pattern: {
                value: /^[0-9]*$/i,
                message: "La cantidad debe ser expresada en números"
              }
            })}
            as="input" 
            placeholder="Valor" 
            name="value"/>
        </Col>
      </Form.Group>

      {errors.value && <AlertForm message={errors.value.message}/>}

      <Form.Group as={Row}>
        <Form.Label column sm={3}>Cantidad máxima de cupones a redimir</Form.Label>
        <Col sm={9}>
          <Form.Control 
            ref={register({
              required: "Ingrese la cantidad máxima de cupones a redimir",
              pattern: {
                value: /^[0-9]*$/i,
                message: "La cantidad debe ser expresada en números"
              }
            })}
            as="input" 
            placeholder=" cantidad máxima de cupones a redimir" 
            name="maximumQuantity"/>
        </Col>
      </Form.Group>

      {errors.maximumQuantity && <AlertForm message={errors.maximumQuantity.message}/>}

      <Form.Group as={Row}>
        <Form.Label column sm={3}>Fecha de Expiración</Form.Label>
        <Col sm={9}>
          <DatePicker
            onChange={val => setExpirationDate(val)}
            locale={locale_es}
            name="expirationDate"
            disabledKeyboardNavigation
            dateFormat="d/MM/yyyy"
            className="form-control" 
            placeholderText="Seleccione la fecha de expiración"
            autoComplete="off"
            selected={expirationDate}
          />
        </Col>
      </Form.Group>
      
      <Form.Group as={Row}>
        <Form.Label column sm={3}>Habilitar</Form.Label>
        <Col sm={9}>
          <Checkbox checked={isEnabled} onChange={() => setIsEnabled(!isEnabled)}>
            <Tag color={isEnabled ? "green" : "volcano"}>
              {isEnabled ? "Habilitado" : "Deshabilitado"}
            </Tag>
          </Checkbox>
        </Col>
      </Form.Group>


      <Form.Group>
        <Col sm={12}>
          {isLoading ? 
            <Spin className="float-right" size="large" /> 
          : 
            <Button variant="primary" className="float-right" type="submit">
              {isUpdate ? 'Actualizar': 'Registrar'} Cupón
            </Button>
          }
        </Col>
      </Form.Group>
    </Form>
  )  
}

export default Coupon