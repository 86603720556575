import React from 'react'
import Loader from 'react-loader-spinner'

import './Loading.css'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"


const Load = (props) => {

  return (
    <div className="center-load">
      <div className="center-load2">
        <Loader
          type={props.type}
          color={props.color}
          height={100}
          width={100}
        />
      </div>
    </div>
  );
}

Load.defaultProps = {
  type: 'Grid',
  color: "#007bff"
}

export default Load;