import React, { useState, useEffect } from "react";
import { fire } from "../../config/firebase";
import { Button, Card, InputGroup, FormControl, Modal, Alert } from "react-bootstrap";
import { MessageBox, SystemMessage, MessageList } from "react-chat-elements";
import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart";
import * as AuthService from "../../services/AuthService";
import ReactTimeAgo from "react-time-ago";
import ReactDOMServer from "react-dom/server";
import ScrollToBottom from "react-scroll-to-bottom";
import { css } from "glamor";

import "./Chat.css";

function App({currentUser}) {
  
  const ROOT_CSS = css({
    height: "100%",
  });

  let message = React.createRef();
  let box = React.createRef();

  const [show, setShow] = useState(false);
  const [user, setUser] = useState({});
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    setUser(AuthService.getUserFromCookies());
    const fireListening = fire.database().ref(`messages/${currentUser.user._id}/messages`);
    fireListening.on("value", (snapshot) => {
      const currentMessages = snapshot.val();
      if (currentMessages != null) {
        setMessages(currentMessages);
      }
    });
    return function cleanup() {
      fireListening.off();
    };
  }, [currentUser]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onEmojiClick = (emoji) => {
    message.current.value = message.current.value + emoji;
  };

  const getInput = (event) => {
    if (event.key === "Enter") {
      submitMessage();
    }
  };

  const submitMessage = () => {
    const messageToSend = {
      text: message.current.value,
      timestamp: fire.database.ServerValue.TIMESTAMP,
      user: {
        _id: user.uuid,
        name: "Support",
        realName: user.fullName,
      },
    };
    fire
      .database()
      .ref(`messages/${currentUser.user._id}/messages`)
      .push(messageToSend);
    message.current.value = "";
		updateUser(messageToSend.text);

   // box.current.scrollTop = box.current.scrollHeight
	};
	
	const updateUser = async (_message) => {

    try{
      const snapshot = await fire.database().ref(`users/${currentUser.user._id}`).once('value');
      const message = {
				lastMessage: _message,
				lastMessageSendBy: "BROSTY",
				unReadMessageApp: 1,
				user: snapshot.val().user,
        timestampLastMessage: fire.database.ServerValue.TIMESTAMP
      };
  
      if(snapshot.val() && snapshot.val().unReadMessageApp){
        message.unReadMessageApp = snapshot.val().unReadMessageApp
        if(Number.isInteger(message.unReadMessageApp))
          message.unReadMessageApp ++
					fire.database().ref(`users/${currentUser.user._id}`).update(message);
      }else{
        fire.database().ref(`users/${currentUser.user._id}`).set(message);
      }
    }catch(error){
      console.error("error al actualizar el usuario en firebase:", error)
    }

  }

  const getDateAgoInString = (date) => {
    let dateInString = ReactDOMServer.renderToString(
      <ReactTimeAgo locale="es" date={date} />
    );

    return dateInString.match(/[^>]+>([^<]+)/)[1];
  };

  const currentMessages = Object.values(messages).map((message, i) => {
    return {
      key: i,
      position: "Support" === message.user.name ? "right" : "left",
      text: message.text,
      titleColor: "Support" === message.user.name ? "white" : "black",
      type: "text",
      style: "Support" === message.user.name ? "color:white" : "color:black",
      dateString: getDateAgoInString(new Date(message.timestamp)),
      onOpen: () => alert("open"),
      onClick: () => alert("click"),
      onScroll: () => alert("scroll"),
      onDownload: () => alert("onDownload"),
      onLoad: () => alert("onLoad"),
    };
  });

  return (
    <div>
      <Card className="text-center card-custom ">
        <Card.Header className="h-20 card-header card-custom">
        <Alert  variant="primary">
         usuario: {currentUser.user.fullName}
         </Alert>
         
        </Card.Header>
        <Card.Body ref={box} className="p-3 card-body-custom overflow-auto">
          <ScrollToBottom style={{height: "100%"}}>
            <MessageList   dataSource={currentMessages} />
            <SystemMessage text={"Final de la conversación"} />
          </ScrollToBottom>
         
        </Card.Body>
        <Card.Footer className="text-muted card-footer">
          <InputGroup className="mb-3">
            <FormControl
              ref={message}
              placeholder="Escriba su mensaje"
              onKeyDown={getInput}
            />
            <InputGroup.Append>
              <button onClick={handleShow}>
                <span role="img" aria-label="">
                  😁
                </span>
              </button>

              <Button variant="primary" onClick={submitMessage}>
                Enviar
              </Button>
            </InputGroup.Append>
          </InputGroup>
        </Card.Footer>
      </Card>

      <Modal size="sm" show={show} onHide={handleClose}>
        <Picker
          title="Selecciona tu Emoji"
          emoji="point_up"
          onSelect={(emoji) => onEmojiClick(emoji.native)}
        />
      </Modal>
    </div>
  );
}

export default App;
